import { Card, Divider } from "@mui/material";
import MDBox from "../../../components/MDBox";
import { useMemo } from "react";
import { T_GroupElev } from "../DashboardIndexLayout";

import MyMDBox from "../../../components/MyMDBox";
import MyMDTypography from "../../../components/MyMDTypography";
import ElevStatus from "../index/ElevStatus";
import Grid from "@mui/material/Grid";


export type T_GroupLayoutProps = {
    color : string;
    items : T_GroupElev;
}

export function GroupLayout({color,items}:T_GroupLayoutProps){
         
    return(
    <Card sx={{ height: "100%"}} title={items.codeDtlNm}  >
        <MyMDBox padding="1rem">
            <MyMDBox
              variant="gradient"
              borderRadius="lg"
              py={2}
              pr={0.5}
              mt={-5}
              height="12.5rem"
              sx={{background:color,height:60,alignContent:'center'}}
            >
                <MyMDTypography
                 textAlign="center"
                 color="white"
                >
                     {items.codeDtlNm}
                </MyMDTypography>
               
            </MyMDBox>
            <MyMDBox padding="1rem">
            <Grid container spacing={2} sx={{mt:1}}>
                 {
               items.list && items.list.map((item,idx)=>{

                    return(
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={2} key={idx}>
                        <ElevStatus elevatorNo={item.elevatorNo} elevatorNm={item.buldNm} elevator={item}  />
                        </Grid>
                    )
                })
            }
            </Grid>
            </MyMDBox>
        </MyMDBox>
    </Card>
    )

}