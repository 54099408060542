import useNav from "../../../uses/useNav";
import MyMDBox from "../../../components/MyMDBox";
import MyMDButton from "../../../components/MyMDButton";
import MyTableStyle from "../../../examples/MyTableStyle";
import { useEffect, useState } from "react";
import { T_alarm, T_elevError } from "../../../models/error";
import apis from "../../../apis";
import MyMDTypography from "../../../components/MyMDTypography";


function ElevAlarm({elevatorNo}:any){
//    console.log(elevatorNo);
    const nav = useNav();
    const [alarmList,setAlarmList] = useState<T_alarm[]>([]);
      const getElevatorError = async() => {
          const result = await apis.elevator.getElevAlarmList({elevatorNo:elevatorNo,pagesize:3});
          console.log("Alarm result:",result);
          if(result.isSuccess){
              setAlarmList(result.data);
          }
  
      }

    useEffect(()=>{
        getElevatorError();
    },[])  
    return(
        <MyTableStyle 
        style={{width:'100%',marginBottom:10}}
        header={{
          title: "최근알림현황",
          rightComponent: (
            <MyMDBox
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <MyMDButton
                onClick={(evt: any) => {
                  evt.preventDefault();
                  nav.push(`/elevator/create`);
                }}
                href="#"
                component="a"
                rel="noreferrer"
                variant="gradient"
                color="light"
              >
                알림더보기
              </MyMDButton>
            </MyMDBox>
          ),
        }}
        table={(() => {
          type column = "제목" | "내용" | "일시";
          type low = {
            [name in column]: JSX.Element;
          };
          const columnList: column[] = [
            "제목",
            "내용",
            "일시",
          ];

          return {
            columns: columnList.map((column) => {
              let width = "";
              switch (column) {
                case "제목": {
                  width = "30%";
                  break;
                }
                case "내용": {
                  width = "40%";
                  break;
                }
                case "일시": {
                  width = "30%";
                  break;
                }
              }

              return {
                Header: column,
                accessor: column,
                width,
              };
            }),
            rows: alarmList && alarmList.map((alarm, idx) => {
              const row: low = {
                ["제목"]: (
                  <MyMDBox
                    onClick={(evt: any) => {
                      evt.preventDefault();
                     // nav.push(`/elevator/${checkingElevator.elevinfo}`);
//                        nav.pushParam('/elevator/info',{elevatorNo:checkingElevator.elevatorNo});
                    }}
                    href="#"
                    component="a"
                    alignItems="center"
                  >
                    <MyMDTypography display="block" variant="caption" fontWeight="medium" mb={1}>
                      {/* 602동 1호기 */}
                    </MyMDTypography>
                    <MyMDTypography
                      display="block"
                      variant="caption"
                      fontWeight="regular"
                      color="text"
                    >
                      {alarm.title}
                    </MyMDTypography>
                  </MyMDBox>
                ),
                ["내용"]: (
                  <MyMDTypography
                    display="block"
                    variant="caption"
                    fontWeight="regular"
                    color="text"
                  >
                    {alarm.content}
                  </MyMDTypography>
                ),
                ["일시"]: (
                  <MyMDTypography
                    display="block"
                    variant="caption"
                    fontWeight="regular"
                    color="text"
                  >
                    {alarm.insDate}
                  </MyMDTypography>
                )
              };
              return row;
            }),
          };
        })()}
      />                 


    )
}
export default ElevAlarm;
