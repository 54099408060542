import { sendApi } from ".";

export type T_code = {
  code: string;
  codeNm: string;
};
export type T_codeDtl = {
  pCode? : string;
  code? : string;
  codeDtlNm?: string;
  etc?: string;
  sort? : number;
};


export type T_codeResult = {
  msg: string;
  isSuccess: boolean;
  list: T_code[];
};

export type T_codeDtlResult = {
  msg: string;
  isSuccess: boolean;
  list?: T_codeDtl[];
};

async function getCodeList(data?: T_code) {
  const response = await sendApi({
    method: "post",
    url: "/code/list",
    data: {
      ...data,
    },
  });

   const list = response.data.data;
  delete response.data.data;
  const result: T_codeResult = {
    ...response.data,
    list,
  };
  return result;
}

async function getCodeDtlList(data?: T_codeDtl) {
  const response = await sendApi({
    method: "post",
    url: "/code/detail/list",
    data: {
      ...data,
    },
  });
  const list = response.data.data;
  delete response.data.data;
  const result: T_codeDtlResult = {
    ...response.data,
    list,
  };
  
  return result;
}

async function deleteCodeDtl(data?: T_codeDtl) {

  const response = await sendApi({
    method: "post",
    url: "/code/detail/delete",
    data: {
      ...data,
    },
  });
  
  const result: T_codeDtlResult = {
    ...response.data,
  };
  
  return result;
}

async function insertCodeDtl(data?: T_codeDtl) {

  const response = await sendApi({
    method: "post",
    url: "/code/detail/insert",
    data: {
      ...data,
      pCode:"GR",
    },
  });
  console.log("response:",response.data)
  const result: T_codeDtlResult = {
    ...response.data,
  };
  
  return result;
}

const code = {
  getCodeList,
  getCodeDtlList,
  deleteCodeDtl,
  insertCodeDtl
};

export default code;
