import useNav from "../../../uses/useNav";
import MyMDBox from "../../../components/MyMDBox";
import MyMDButton from "../../../components/MyMDButton";
import MyTableStyle from "../../../examples/MyTableStyle";
import { useEffect, useState } from "react";
import { T_elevError } from "../../../models/error";
import apis from "../../../apis";
import MyMDTypography from "../../../components/MyMDTypography";
import { T_checkHist } from "../../../models/elevator";
import { T_check } from "../../../apis/check";


function ElevCheck({elevatorNo}:any){
 //   console.log(elevatorNo);
    const nav = useNav();
    const [data,setData] = useState<T_check[]>([]);
      const getElevatorCheck = async() => {
          const result = await apis.check.getList({elevatorNo:elevatorNo,page:1,pagesize:3});
          console.log("result:",result);
          if(result.isSuccess){
              setData(result.checkList);
          }
  
      }

    useEffect(()=>{
      getElevatorCheck();
    },[])  
    return(
        <MyTableStyle 
        style={{width:'100%',marginBottom:10}}
        header={{
          title: "최근점검현황",
          rightComponent: (
            <MyMDBox
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <MyMDButton
                onClick={(evt: any) => {
                  evt.preventDefault();
                  nav.push(`/elevator/create`);
                }}
                href="#"
                component="a"
                rel="noreferrer"
                variant="gradient"
                color="light"
              >
                점검더보기
              </MyMDButton>
            </MyMDBox>
          ),
        }}
        table={(() => {
          type column = "점검결과" | "점검유효성" | "일시";
          type low = {
            [name in column]: JSX.Element;
          };
          const columnList: column[] = [
            "점검결과",
            "점검유효성",
            "일시",
          ];

          return {
            columns: columnList.map((column) => {
              let width = "";
              switch (column) {
                case "점검결과": {
                  width = "30%";
                  break;
                }
                case "점검유효성": {
                  width = "40%";
                  break;
                }
                case "일시": {
                  width = "30%";
                  break;
                }
              }

              return {
                Header: column,
                accessor: column,
                width,
              };
            }),
            rows: data && data.map((check, idx) => {
              const row: low = {
                ["점검결과"]: (
                  <MyMDBox
                    onClick={(evt: any) => {
                      evt.preventDefault();
                     // nav.push(`/elevator/${checkingElevator.elevinfo}`);
//                        nav.pushParam('/elevator/info',{elevatorNo:checkingElevator.elevatorNo});
                    }}
                    href="#"
                    component="a"
                    alignItems="center"
                  >
                    <MyMDTypography display="block" variant="caption" fontWeight="medium" mb={1}>
                      {/* 602동 1호기 */}
                    </MyMDTypography>
                    <MyMDTypography
                      display="block"
                      variant="caption"
                      fontWeight="regular"
                      color="text"
                    >
                      {check.status}
                    </MyMDTypography>
                  </MyMDBox>
                ),
                ["점검유효성"]: (
                  <MyMDTypography
                    display="block"
                    variant="caption"
                    fontWeight="regular"
                    color="text"
                  >
                    {check.cstatus}
                  </MyMDTypography>
                ),
                ["일시"]: (
                  <MyMDTypography
                    display="block"
                    variant="caption"
                    fontWeight="regular"
                    color="text"
                  >
                    {check.insDate}
                  </MyMDTypography>
                )
              };
              return row;
            }),
          };
        })()}
      />                 


    )
}
export default ElevCheck;
