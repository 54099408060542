import { T_admin } from "../contexts/user";
import { sendApi } from ".";

export type T_userRole = "USER"|"GUEST";

export type T_user = {
  hp?: string | null;
  role: T_userRole;
  userNm?: string;
  userId: string;
  userPw?: string | null;
  bizName?: string;
  bizRegNo?: string;
  license?: string;
};

export type T_responseCommon ={
  msg:string,
  isSuccess: boolean;
}

export type T_userGetListData = {};

export type T_userGetListResult = {
  msg: string;
  isSuccess: boolean;
  userList: T_user[];
};
export type T_userGetAdminListResult = {
  msg: string;
  isSuccess: boolean;
  userList: T_admin[];
};

async function getList(data?: T_userGetListData) {
  const response = await sendApi({
    method: "post",
    url: "/user/list",
    data: {
      ...data,
    },
  });
  console.log("test:",response.data.data);
  const userList :any = response.data.data.list;
  delete response.data.data;
  const result: T_userGetListResult = {
    ...response.data,
    userList,
  };
  return result;
}

export type T_userGetData = {
  id: string;
};

export type T_adminGetData = {
  userId: string;
  userPass?: string;
};
export type T_userGetResult = {
  msg: string;
  isSuccess: boolean;
  user: T_user | null;
};

export type T_adminGetResult = {
  msg: string;
  isSuccess: boolean;
  user: T_admin | null;
};

export type T_loginAdminResult = {
  msg : string;
  isSuccess: boolean;
  user:T_admin | null;

}

async function get(data: T_userGetData) {
  const response = await sendApi({
    method: "post",
    url: "/user/get",
    data: {
      ...data,
    }
  });

  console.log(response.data.data);
 let user: T_user | null = null;
 user = response.data.data;
  // for (let idx = 0; idx < response.data.data.length; idx++) {
  //   const selUser = response.data.data[idx];
  //   if (selUser.userId === data.id) {
  //     user = selUser;
  //     break;
  //   }
  // }
  delete response.data.data;
  const result: T_userGetResult = {
    ...response.data,
    user,
  };
  return result;
}

async function getAdminInfo(data: T_adminGetData) {
  const response = await sendApi({
    method: "post",
    url: "/user/admin/info",
    data: {
      ...data,
    }
  });

  console.log("response:",response.data.data);
 let user: T_admin | null = null;
 user = response.data.data;
  // for (let idx = 0; idx < response.data.data.length; idx++) {
  //   const selUser = response.data.data[idx];
  //   if (selUser.userId === data.id) {
  //     user = selUser;
  //     break;
  //   }
  // }
  delete response.data.data;
  const result: T_adminGetResult = {
    ...response.data,
    user,
  };
  return result;
}


async function getAdminList(data?: T_userGetListData) {
  const response = await sendApi({
    method: "post",
    url: "/user/admin/list",
    data: {
      ...data,
    },
  });
  const userList = response.data.data.list;
  delete response.data.data;
  const result: T_userGetAdminListResult = {
    ...response.data,
    userList,
  };
  return result;
}


async function loginAdmin(data?:T_adminGetData){
  const response = await sendApi({
    method: "post",
    url: "/user/admin/login",
    data: {
      ...data,
    },
  });
  console.log(data);
  const user = response.data.data;
  delete response.data.data;
  const result  :T_loginAdminResult = {
      ...response.data,
      user

  }
  return result;
}

async function insertAdmin(data?:T_admin){
  
  console.log(data);
  const response = await sendApi({
    method: "post",
    url: "/user/admin/insert",
    data: {
      ...data,
    },
  });
  console.log(data);
  const user = response.data.data;
  delete response.data.data;
  const result  :T_loginAdminResult = {
      ...response.data,
      user

  }
  return result;
}

async function deleteAdmin(data?:T_admin){
  const response = await sendApi({
    method: "post",
    url: "/user/admin/delete",
    data: {
      ...data,
    },
  });
  const user = response.data.data;
  delete response.data.data;
  const result  :T_loginAdminResult = {
      ...response.data,
      user

  }
  return result;
}


async function roleUser(data?:T_user){
  const response = await sendApi({
    method: "post",
    url: "/user/role",
    data: {
      ...data,
    },
  });
  delete response.data.data;
  const result  :T_responseCommon = {
      ...response.data,
    }
  return result;
}


const user = {
  getList,
  get,
  getAdminList,
  getAdminInfo,
  loginAdmin,
  insertAdmin,
  deleteAdmin,
  roleUser,
};

export default user;
