/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

==========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MyMDBox from "../../components/MyMDBox";
import MyMDButton from "../../components/MyMDButton";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import { useCallback, useEffect, useState } from "react";
import MyMDTypography from "../../components/MyMDTypography";
import useNav from "../../uses/useNav";
import apis from "../../apis";
import MyEdit from "../../examples/MyEdit";
import MyMDInput from "../../components/MyMDInput";
import { useUser } from "../../contexts/user";
import { Navigate } from "react-router-dom";
import {T_user} from "../../apis/user"
import { Button } from "@mui/material";

function UserDetailLayout() {
  const nav = useNav();
  const { user } = useUser();
  const state = nav.state as {userid:string}
  const id = state.userid;
  const isCreate = nav.pathname.includes("/user/create");
  const [isInit, setIsInit] = useState(false);

  const [userInfo,setUserInfo] = useState<T_user>();

  
  const userRole = async(user:T_user) =>{
    const result = await apis.user.roleUser(user);
    if(result.isSuccess){
        alert("승인 처리 되었습니다")
        getUser();
    }else{
      alert("승인 처리중 오류가 발생했습니다.")
    }
  }

  const userRoleDelete = async(user:T_user) =>{
    const result = await apis.user.roleUser(user);
    if(result.isSuccess){
        alert("승인 해제 되었습니다")
        getUser();
    }else{
      alert("승인 해제중 오류가 발생했습니다.")
    }
  }


  const getUser = useCallback(async () => {
    try {
      const result = await apis.user.get({
        id,
      });
      if (result.isSuccess && result.user) {
        const user = result.user;
        setUserInfo(result.user);
      } else {
        console.log(result.msg);
      }
    } catch (err: any) {
      console.log(err.message);
    }
  }, [id]);

  useEffect(() => {
    if (!isInit) {
      const init = async () => {
        if (!isCreate) {
          await getUser();
        }
        setIsInit(true);
      };
      init();
    }
  }, [isCreate, getUser, isInit]);

  if (!user) {
    return <Navigate to="/signin" />;
  } else {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MyEdit 
          header={{
            title: "회원정보",
          }}
        >
        <></>
       <MyMDBox pt={1} pl={1} pr={1} borderRadius="md" bgColor="#FFFFFF">
         <MyMDBox pr={1}  pt={1}  pb={2} >
        
        <table   className="myTable"
                    style={{
                      margin: 5,
                      tableLayout: "fixed",
                    }}
        ><thead>
        </thead>
        <tbody>
        <tr>
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 이름 </MyMDTypography></td>
              <td><MyMDTypography ml={1} variant="caption"  fontWeight="medium">{userInfo?.userNm}</MyMDTypography></td>
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 전화번호 </MyMDTypography></td>
              <td><MyMDTypography ml={1} variant="caption"  fontWeight="medium">{userInfo?.hp}</MyMDTypography></td>
              </tr>
              <tr>
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 회사명 </MyMDTypography></td>
              <td><MyMDTypography ml={1} variant="caption"  fontWeight="medium">{userInfo?.bizName}</MyMDTypography></td>
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 사업자번호 </MyMDTypography></td>
              <td><MyMDTypography ml={1} variant="caption"  fontWeight="medium">{userInfo?.bizRegNo}</MyMDTypography></td>
              </tr>
              <tr>
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 자격번호 </MyMDTypography></td>
              <td ><MyMDTypography ml={1} variant="caption"  fontWeight="medium">{userInfo?.license}</MyMDTypography></td>
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 승인여부 </MyMDTypography></td>
              <td><MyMDTypography ml={1} variant="caption"  fontWeight="medium">
                {userInfo?.role=="USER"?
                <>
                승인<Button                    variant="contained"

                          style={{padding:0,margin:10,color:"#ffffff",background:"#FF0000"}}
                          size="small"
                            onClick={()=>{
                              if(confirm(userInfo?.userNm+" 님을 정말로 승인 해제 하시겠습니까?")){
                                userRoleDelete({userId:userInfo.userId,role:"GUEST"});
                              }
                            }}>승인해제</Button>
                </>
                :
                <>
                  미승인<Button                    variant="contained"
                          style={{padding:0,margin:2,color:"#ffffff",background:"#0000ff"}}
                          size="small"
                            onClick={()=>{
                              if(confirm(userInfo?.userNm+" 님을 정말로 승인하시겠습니까?")){
                              if(userInfo)  userRole({userId:userInfo.userId,role:"USER"})
                              }
                            }}>승인처리</Button>
                  </>
                }
                </MyMDTypography></td>
              </tr>
              <tr >
              <td style={{backgroundColor:"#eeeeee",height:300}}>  <MyMDTypography ml={1} variant="caption"> 비고 </MyMDTypography></td>
              <td colSpan={3}><MyMDTypography ml={1} variant="caption"  fontWeight="medium" ></MyMDTypography></td>
              </tr>
        </tbody>
        </table>
        <MyMDBox m={1} justifyContent="center" display="flex">
                          <MyMDBox pr={1}>
                          <MyMDButton m={1}   variant="gradient"
                           onClick={()=>{
                            nav.goBack();
                           }}
                            color="info">목록</MyMDButton>
                          </MyMDBox>
                          <MyMDBox pr={1}>
                          <MyMDButton m={1}   
                            color="warning">삭제</MyMDButton>
                          </MyMDBox>
                          </MyMDBox>
        </MyMDBox>
        </MyMDBox>
        </MyEdit>
      </DashboardLayout>
    );
  }
}

export default UserDetailLayout;
