
import { AppStateContext } from "./appState";
import { UserContext } from "./user";
import { GlobalProgressDialog, ProgressContextProvider } from "./ProgressContextProvider";

export type T_ContextsProps = {
  children: JSX.Element;
};

function Contexts({ children }: T_ContextsProps) {
  return (
    <AppStateContext>
      <ProgressContextProvider>
      <GlobalProgressDialog />
      <UserContext>{children}</UserContext>
      </ProgressContextProvider>
    </AppStateContext>
  );
}

export default Contexts;
