import useNav from "../../uses/useNav";
import MyMDButton from "../../components/MyMDButton";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MyTable from "../../examples/MyTable";
import { useEffect, useState } from "react";
import MyMDBox from "../../components/MyMDBox";
import MyMDTypography from "../../components/MyMDTypography";
import MyIcon from "../../components/MyIcon";
import MDTypography from "../../components/MDTypography";
import apis from "../../apis";
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { T_board, T_resultBoard } from "../../apis/board";
import { display } from "@mui/system";
import server from "../../values/server";
function NoticeList()
{
    const nav = useNav();
    const [totalPage,setTotalPage] = useState();
    const [dataList,setDataList] = useState<T_board[]  >([]);

    const page = nav.params.page;
    const pagesize = 10;
    const [total, setTotal] = useState<null | number>(null);

    const selectBoard = async() =>{
          const result = await apis.board.selectBoard({delYn:'N'});
          if(result.isSuccess){
            console.log(result.list)
          if(result.list) setDataList(result.list);
          }
    }


    const updateBoard = async(idx:number,type:string,status:string) =>{
        if(type==="D"){
            const result = await apis.board.updateBoard({idx:idx,delYn:'Y'})
            if(result.isSuccess){
              alert("삭제 되었습니다");
              selectBoard();
            }else{
              alert("삭제중 오류가 발생했습니다.");
            }
       }else{
        console.log(idx+":"+status)
        const result = await apis.board.updateBoard({idx:idx,displayYn:status})
        if(result.isSuccess){
          alert("수정 되었습니다");
          selectBoard();
        }else{
          alert("수정중 오류가 발생했습니다.");
        }

      }



    }

    useEffect(()=>{
        selectBoard();
    },[])

    return(
        <DashboardLayout>
        <DashboardNavbar />
        {dataList && (
        <MyTable
             pagesize={pagesize}
             totalPage={totalPage}
              header={{
                title: "광고보드 관리",
                rightComponent: (
                  <MyMDBox
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <MyMDButton
                      onClick={(evt: any) => {
                        evt.preventDefault();
                        nav.push(`/board/create`);
                      }}
                      href="#"
                      component="a"
                      rel="noreferrer"
                      variant="gradient"
                      color="light"
                    >
                      등록
                    </MyMDButton>
                  </MyMDBox>
                ),
              }}
              table={(() => {
                type column = "no" | "타입" | "제목" | "이미지" | "노출시간" | "비고";
                type low = {
                  [name in column]: JSX.Element;
                };
                const columnList: column[] = [
                  "no",
                  "타입",
                  "제목",
                  "이미지",
                  "노출시간",
                  "비고",
                ];

                return {
                  columns: columnList.map((column) => {
                    let width = "";
                    switch (column) {
                      case "no": {
                        width = "5%";
                        break;
                      }
                      case "타입": {
                        width = "20%";
                        break;
                      }
                      case "제목": {
                        width = "30%";
                        break;
                      }
                      case "이미지": {
                        width = "25%";
                        break;
                      }
                      case "노출시간": {
                        width = "10%";
                        break;
                      }
                      case "비고": {
                        width = "10%";
                        break;
                      }
                    }

                    return {
                      Header: column,
                      accessor: column,
                      width,
                    };
                  }),
                  rows: dataList.map((data:T_board, idx:number) => {

                    const imgUrl = (data.files !== null && data.files !== undefined && data.files.length > 0)?data.files[0].fileUrl:"";
                      if(imgUrl !== "" ) console.log(imgUrl);
                    const row: low = {
                      no: (
                        <MyMDTypography
                          display="block"
                          variant="caption"
                          color="text"
                          fontWeight="regular"
                        >
                          {Number(total)-(pagesize*(Number(page)-1))-idx}
                        </MyMDTypography>
                      ),
                      ["타입"]: (
                        <MyMDTypography
                          // onClick={(evt: any) => {
                          //   evt.preventDefault();
                          //   nav.push(`/group/0`);
                          // }}
                          // href="#"
                          display="block"
                          component="a"
                          variant="caption"
                          fontWeight="medium"
                        >
                          {data.type}
                        </MyMDTypography>
                      ),
                      ["제목"]: (
                        <MyMDBox
                          onClick={(evt: any) => {
                            evt.preventDefault();
                      //      nav.push(`/elevator/${elevator.elevatorNo}`);
//                            nav.pushParam('/elevator/info',{elevatorNo:elevator.elevatorNo});
                          }}
                          href="#"
                          component="a"
                          alignItems="center"
                        >
                          <MyMDTypography
                            display="block"
                            variant="caption"
                            fontWeight="medium"
                            mb={1}
                          >
                            {/* 602동 1호기 */}
                            {data.title}
                          </MyMDTypography>
                        </MyMDBox>
                      ),
                      ["이미지"]:
                            data.type=="img" && imgUrl !== "" ?
                      (
                         <div>
                         <img src={server.url+"/upload/"+imgUrl} alt="Image" width={100} height={100} />
                       </div>
                      ):(
                        <SlideshowIcon fontSize="large" color="primary"  />
                        )
                      ,
                      ["노출시간"]: (
                        <MyMDBox
                          component="a"
                          alignItems="center"
                        >
                          <MyMDTypography
                            display="block"
                            variant="caption"
                            fontWeight="medium"
                            mb={1}
                          >
                            {/* 602동 1호기 */}
                            {data.sec} 초
                          </MyMDTypography>
                        </MyMDBox>
                      ),
                      ["비고"]: <MyMDBox
                                component="a"
                                href={`#`}
                                alignItems="center"
                                p={1}
                                >
                                   <MyMDButton m={1}   variant="gradient"
                                     onClick={()=>{
                                      if(data.displayYn==="Y"){
                                      if(confirm("화면 송출을 중지 하시겠습니까?")){
                                        if(data.idx) updateBoard(data.idx,"S","N")
                                      }
                                      }else{
                                        if(confirm("화면 송출을 시작 하시겠습니까?")){
                                          if(data.idx) updateBoard(data.idx,"S","Y")
                                        }
  
                                      }
                                    }}
                                     sx={{marginRight:1}}
                                     size="small"
                                    color={data.displayYn==="Y"?"info":"error"}
                                  >{data.displayYn==="Y"?"정지":"송출"}</MyMDButton>
                                   <MyMDButton m={1}   variant="gradient"
                                     onClick={()=>{
                                      if(confirm("정말로 삭제하시겠습니까?")){
                                        if(data.idx) updateBoard(data.idx,"D","Y")
                                      }
                                     }}
                                     size="small"
                                  color="primary">삭제</MyMDButton>

                      </MyMDBox>,
                    };
                    return row;
                  })                   
                  ,
                };
              })()}
            />
            )}
        </DashboardLayout>

    )
}

export default NoticeList;