import { T_file, getApi, sendApi } from ".";

export type T_board = {

  idx? : number;
  title? : string;
  type? : string;
  fileMstIdx? : number;
  files? : T_file[] | null;
  sec? : number;
  sDate? : string;
  eDate? : string;
  displayYn : string;
  delYn : string;
}

export type T_resultBoard = {
  isSuccess? : boolean;
  msg? : string;
  total? : number;
  totalPage? : number;
  list? : T_board[];
}


async function getNotice() {

    const response = await sendApi({
      method: "post",
      url: "/board/listNotice",
      data: {
      },
    });

    return response.data.data[0];
}

async function updateNotice(data:any) {

    const response = await sendApi({
      method: "post",
      url: "/board/insertNotice",
      data: {
        ...data
      },
    });

    return response.data;
}


async function insertBoard(data:any) {
    const response  = await sendApi({
      method: "post",
      url: "/board/insertBoard",
      data: data,
      isForm:true,
    });
  
    return response.data;
}


async function updateBoard(data:any) {
  console.log(data);
  const response  = await sendApi({
    method: "post",
    url: "/board/updateBoard",
    data: data,
    isForm:true,
  });

  return response.data;
}


async function selectBoard(data:any) {

    const response = await sendApi({
      method: "post",
      url: "/board/listBoard",
      data: data,
    });
    
    const list = response.data.data;
    delete response.data.data;

    const result : T_resultBoard = {
      ...response.data
      ,list
    }

    console.log(result);
    return result;
}

const board = {
        getNotice,
        updateNotice,
        insertBoard,
        selectBoard,
        updateBoard,
  };

export default board;

