/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

==========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MyMDBox from "../../components/MyMDBox";
import MyMDButton from "../../components/MyMDButton";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import { useCallback, useEffect, useState } from "react";
import MyMDTypography from "../../components/MyMDTypography";
import useNav from "../../uses/useNav";
import apis from "../../apis";
import MyEdit from "../../examples/MyEdit";
import MyMDInput from "../../components/MyMDInput";
import { useUser } from "../../contexts/user";
import { Navigate } from "react-router-dom";
import { T_codeDtl, T_codeDtlResult } from "../../apis/code";
import { TextField } from "@mui/material";

function GroupDetailLayout() {
  const nav = useNav();
  const { user } = useUser();
  const {code}  = nav.state as {code:string}
  const isCreate = nav.pathname.includes("/group/create");
  const [isInit, setIsInit] = useState(false);

  const [group,setGroup] = useState<T_codeDtl>({pCode:"GR"});
  const getGroup = useCallback(async () => {
    const result = await apis.code.getCodeDtlList({code:code,pCode:"GR"})
    console.log(result)
    if(result.isSuccess && result.list){
        if(result.list.length ==1){
          setGroup(result.list[0]);
        }
    }else{
      
    }
  }, []);

  const deleteGroup = useCallback(async () => {
    const result = await apis.code.deleteCodeDtl({code:code,pCode:"GR"})
    console.log(result)
    if(result.isSuccess){
    alert("삭제가 완료 되었습니다")
    }else{
      alert("삭제중 오류가 발생하였습니다.")
    }
  }, []);

  const updateGroup = useCallback(async () => {
    setGroup({...group,pCode:"GR"});
    if(!group?.codeDtlNm){alert("코드명를 입력하십시요"); return;}

    const result = await apis.code.insertCodeDtl(group)
    if(result.isSuccess){
    alert("수정이 완료 되었습니다")
      }else{
      alert("수정 중 오류가 발생하였습니다.")
    }
  }, [group]);


  useEffect(() => {
    if (!isInit) {
      const init = async () => {
        if (!isCreate) {
          await getGroup();
        }
        setIsInit(true);
      };
      init();
    }
  }, [isCreate, getGroup, isInit]);

  if (!user) {
    return <Navigate to="/signin" />;
  } else {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        {isInit && (
          <>
            <MyEdit
              header={{
                title: "그룹 정보",
              }}
              
            >
              <></>
             <MyMDBox pt={1} pl={1} pr={1} borderRadius="md" bgColor="#FFFFFF">
                    <MyMDBox pr={1}  pt={1}  pb={0} >
        <table   className="myTable"
                    style={{
                      margin: 5,
                      tableLayout: "fixed",
                    }}
        ><thead>
        </thead>
        <tbody>
              <tr>
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 그룹코드 </MyMDTypography></td>
              <td><MyMDTypography ml={1} variant="caption"  fontWeight="medium">{group?.code}</MyMDTypography></td>
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption">그룹명</MyMDTypography></td>
              <td>
              <MyMDInput value={group?.codeDtlNm}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                     setGroup({...group,codeDtlNm:value});
                                    }
                                }
                                autoFocus
                                style={{width:'60%'}}
                            />
              </td>
              </tr>
              <tr >
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 비고 </MyMDTypography></td>
              <td colSpan={3} style={{height:'100%',padding:10}}>  <TextField
                 multiline
                 rows={4}
                 value={group?.etc}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                    setGroup({...group,etc:value});
                                }}
                                autoFocus
                                style={{width:'100%'}}
                /></td>
              </tr>
        </tbody>
        </table>
        <MyMDBox m={1} justifyContent="center" display="flex">
                          <MyMDBox pr={1}>
                          <MyMDButton m={1}   variant="gradient"
                            onClick={()=>{

                              if(confirm("정말로 수정하시겠습니까?")){
                                  
                                updateGroup();
                              }
                            }}
                            color="info">수정</MyMDButton>
                          </MyMDBox>
                          <MyMDBox pr={1}>
                          <MyMDButton m={1}   
                            onClick={()=>{
                                if(confirm(group?.codeDtlNm+" 그룹을 정말로 삭제하시겠습니까")){
                                    deleteGroup();
                                }
                            }}
                            color="warning">삭제</MyMDButton>
                          </MyMDBox>
                          </MyMDBox>
        </MyMDBox>
        </MyMDBox>     
            </MyEdit>
            <Footer />
          </>
        )}
      </DashboardLayout>
    );
  }
}

export default GroupDetailLayout;
