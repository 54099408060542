import { Navigate } from "react-router-dom";
import { useUser } from "../../contexts/user";
import useNav from "../../uses/useNav";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MyEdit from "../../examples/MyEdit";
import MyMDBox from "../../components/MyMDBox";
import MyMDTypography from "../../components/MyMDTypography";
import MyMDButton from "../../components/MyMDButton";
import Footer from "../../examples/Footer";
import { TextField } from "@mui/material";
import MyMDInput from "../../components/MyMDInput";
import { useCallback, useState } from "react";
import { T_codeDtl } from "../../apis/code";
import apis from "../../apis";


function GroupCreate(){

    const nav = useNav();
    const { user } = useUser();
    const[group,setGroup] = useState<T_codeDtl>({pCode:"GR"} );
    const insertGroup = useCallback(async () => {
        if(!group.code){alert("코드를 입력하십시요"); return;}
        if(!group.codeDtlNm){alert("코드명를 입력하십시요"); return;}

        const result = await apis.code.insertCodeDtl(group)
        if(result.isSuccess){
        alert("등록이 완료 되었습니다")
        nav.push("/group");
        }else{
          alert("등록 중 오류가 발생하였습니다.")
        }
      }, [group]);

    
    if (!user) {
        return <Navigate to="/signin" />;
      } else {
        return (
          <DashboardLayout>
            <DashboardNavbar />
              <>
                <MyEdit
                  header={{
                    title: "그룹 등록",
                  }}
                  
                >
                  <></>
                 <MyMDBox pt={1} pl={1} pr={1} borderRadius="md" bgColor="#FFFFFF">
                        <MyMDBox pr={1}  pt={1}  pb={0} >
            <table   className="myTable"
                        style={{
                          marginTop: 5,
                          tableLayout: "fixed",
                        }}
            ><thead>
            </thead>
            <tbody>
                  <tr>
                  <td style={{backgroundColor:"#eeeeee",width:150}}>  <MyMDTypography ml={1} variant="caption"> 그룹코드 </MyMDTypography></td>
                  <td style={{width:200}}><MyMDInput value={group?.code}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                     setGroup({...group,code:value});
                                    }
                                }
                                autoFocus
                                style={{width:'60%'}}
                            /></td>
                  <td style={{backgroundColor:"#eeeeee",width:150}}>  <MyMDTypography ml={1} variant="caption">그룹명</MyMDTypography></td>
                  <td><MyMDInput value={group?.codeDtlNm}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                    setGroup({...group,codeDtlNm:value});
                                }
                                }
                                autoFocus
                                style={{width:'60%'}}
                            /></td>
                  </tr>
                  <tr >
                  <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 비고 </MyMDTypography></td>
                  <td colSpan={3} style={{height:'100%',padding:10}}>
                  <TextField
                 multiline
                 rows={4}
                 value={group?.etc}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                    setGroup({...group,etc:value});
                                }}
                                autoFocus
                                style={{width:'100%'}}
                />

                  </td>
                  </tr>
            </tbody>
            </table>
            <MyMDBox m={1} justifyContent="center" display="flex">
                              <MyMDBox pr={1}>
                              <MyMDButton m={1}   variant="gradient"
                                color="info"
                                onClick={()=>{
                                    // if(confirm(id+" 아이디를 정말로 삭제하시겠습니까")){
                                    //     deleteAdmin();
                                    // }
                                    insertGroup();
                                   
                                }}
                                >등록</MyMDButton>
                              </MyMDBox>
                              <MyMDBox pr={1}>
                              <MyMDButton m={1}   variant="gradient"
                               
                                color="secondary">취소</MyMDButton>
                              </MyMDBox>
                              </MyMDBox>
            </MyMDBox>
            </MyMDBox>     
                </MyEdit>
                <Footer />
              </>
          </DashboardLayout>
                      )
        
   }
}

export default GroupCreate;