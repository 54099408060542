import { CircularProgress } from "@mui/material";
import { ReactNode, createContext, useContext, useState } from "react";

// 타입스크립트에서 createContext에 전달하는 기본값 타입을 정의합니다.
interface ProgressDialogContextProps {
    showProgress: boolean;
    setShowProgress: React.Dispatch<React.SetStateAction<boolean>>;
  }

const ProgressContext = createContext<ProgressDialogContextProps | null>(null);

export function ProgressContextProvider({children}: { children: ReactNode }){
    const [showProgress, setShowProgress] = useState(false);
  return (
    <ProgressContext.Provider value={{ showProgress, setShowProgress }}>
      {children}
    </ProgressContext.Provider>
  );
}
export function useProgress() {
    const context = useContext(ProgressContext);
    if (!context) {
      throw new Error('useProgressDialog must be used within a ProgressDialogProvider');
    }
    return context;
}
export function GlobalProgressDialog() {
    const { showProgress } = useProgress();
  
    if (!showProgress) return null;
  
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.5)',zIndex: 9999999 }}>
        <CircularProgress />
      </div>
    );
  }