import { Box, Card, Grid } from "@mui/material";
import MyMDBox from "../../../components/MyMDBox";
import MDBox from "../../../components/MDBox";
import MyMDTypography from  "../../../components/MyMDTypography";
import Icon from "@mui/material/Icon";
import { useCallback, useEffect, useState } from "react";
import apis from "../../../apis";
import elevator, { T_elevStatus, T_floorInfo } from "../../../apis/elevator";
import { useTransition, animated, useSpring } from 'react-spring';
import useNav from "../../../uses/useNav";
import values from "../../../values";
import { T_elevator } from "../../../models/elevator";
import mqtt, { MqttClient } from 'mqtt';
import util from "../../../modules/util";


type ElevStatusProps = {
    elevatorNo: string;
    elevatorNm : string;
    elevator?:T_elevator;
    onChildClick?: (elevatorNo:String) => void;
  }

function ElevStatus(props:ElevStatusProps) {
    

    const [cursorType, setCursorType] = useState("default");
    const nav = useNav();

    const elevatorNo = props.elevatorNo;
    const elevator = props.elevator;
    const [elev,setElev] = useState<T_elevStatus>()
    const [floor,setFloor] = useState<T_floorInfo[]|null|undefined>();

    const up_ani = ["/images/up01.png","/images/up02.png","/images/up03.png","/images/up04.png","/images/up05.png","/images/up06.png"];

    const handleClick = (elevatorNo:String) => {
            if(props.onChildClick){
                props.onChildClick(elevatorNo);
            }


      };

      const handleMouseEnter = () => {
        setCursorType("pointer");
      };
    
      const handleMouseLeave = () => {
        setCursorType("default");
      };
    
    const getElevStatus = useCallback(async () => {
        try {
           const result = await apis.elevator.getElevStatus(elevatorNo);
        if (result.isSuccess) {
          console.log("ElevStatus:",result)
          result.data && setElev(result.data);

     
        }
                //   if (result.isSuccess) {
        //   }
        } catch (err: any) {
          console.log(err.message);
        }
      }, []);    

      const getFloorinfo = useCallback(async () => {
        try {
           const result = await apis.elevator.getElevFloor(elevatorNo)
           setFloor(result.data);
        //   if (result.isSuccess) {
        //   }
            console.log(result)
        } catch (err: any) {
          console.log(err.message);
        }
      }, []);    
     //  mqtt 를 사욯해서 화면 수정
     const [client, setClient] = useState<MqttClient | null>(null);
     const [message, setMessage] = useState<string | null>(null);
     const MqttCon = () =>{

   
      // MQTT 브로커에 연결
       const mqttClient = mqtt.connect('wss://saehanel.co.kr:9002',{username:'saehanel',password:'7389'})
       // CA 인증서 설정}); // 브로커 URL을 여기에 입력하세요 (예: public broker)
   
       
     // 클라이언트를 상태로 설정
     setClient(mqttClient);
     // 연결 성공 시
       mqttClient.on('connect', () => {
         console.log('MQTT 연결 성공');
         // 구독할 토픽 설정
         mqttClient.subscribe('/elevator/status/'+elevatorNo, (err) => {
           if (!err) {
             console.log('토픽 구독 성공');
           }
         });
       });

       // 메시지를 받을 때
       mqttClient.on('message', (topic, payload) => {
         let upCall:boolean[]=Array(64).fill(false);
         for (let i = 0; i < 6; i++) {
           const b = payload[i + 7]; // list에서 byte 가져오기
           for (let j = 0; j < 8; j++) {
             upCall[i * 8 + j] = (b & (1 << j)) !== 0; // 각 비트 추출하여 true/false로 변환
           }
         }
 
         let downCall:boolean[]=Array(64).fill(false);
         for (let i = 0; i < 6; i++) {
           const b = payload[i + 14]; // list에서 byte 가져오기
           for (let j = 0; j < 8; j++) {
             downCall[i * 8 + j] = (b & (1 << j)) !== 0; // 각 비트 추출하여 true/false로 변환
           }
         }
         let innerCall:boolean[]=Array(64).fill(false);
         for (let i = 0; i < 6; i++) {
           const b = payload[i + 21]; // list에서 byte 가져오기
           for (let j = 0; j < 8; j++) {
             innerCall[i * 8 + j] = (b & (1 << j)) !== 0; // 각 비트 추출하여 true/false로 변환
           }
         }
 
         let status:T_elevStatus = {
           cfloor: payload[3],
           errorCode: payload[4],
           errorFloor: payload[5],
           upCall: upCall,
           downCall: downCall,
           innerCall: innerCall,
           speed: payload[20],
           full: util.getBitAtPosition(payload[1],2) ,
           down: util.getBitAtPosition(payload[0],1),
           floorHeight: util.getBitAtPosition(payload[2],6),
           gisa: util.getBitAtPosition(payload[0],5),
           moving: util.getBitAtPosition(payload[0],7),
           auto: util.getBitAtPosition(payload[0],4),
           fire: util.getBitAtPosition(payload[1],1),
           alarmElectric: util.getBitAtPosition(payload[2],2),
           doorLock: util.getBitAtPosition(payload[0],3),
           fail: util.getBitAtPosition(payload[1],7),
           earthQuake: util.getBitAtPosition(payload[2],2),
           doorOpen: util.getBitAtPosition(payload[1],4),
           doorError: util.getBitAtPosition(payload[2],5),
           test: util.getBitAtPosition(payload[2],7),
           over: util.getBitAtPosition(payload[1],3),
           lock: util.getBitAtPosition(payload[0],6),
           doorClose: util.getBitAtPosition(payload[1],5),
           move: util.getBitAtPosition(payload[0],2),
           safePoint: util.getBitAtPosition(payload[1],6),
           group: util.getBitAtPosition(payload[1],0),
           error: util.getBitAtPosition(payload[2],4),
           up: util.getBitAtPosition(payload[0],0)
         }
       //  console.log('message:',status);
         setElev(status);
       });

   // 클린업 함수로 컴포넌트가 언마운트될 때 연결 해제
     return () => {
       mqttClient.end();
     };

     
     }

     useEffect(() => {
     getFloorinfo();
       MqttCon();
    
       }, []);


      useEffect(() => {
        // const interval = setInterval(() => {
        //     getElevStatus();
        // }, 2000);
        // return () => clearInterval(interval);
        
      }, []);

      
      const getDriverStatus= (elev:T_elevStatus|null|undefined) => {
        if(!elev?.auto) {
            return( <MyMDTypography variant="h6" color="info"
            alignContens="center">정상 운행</MyMDTypography>)
        }else{
            return(<MyMDTypography variant="h6" color="error"
            alignContens="center">점검중</MyMDTypography>) 
        }
      }
      
      const getUpStatus = (elev:T_elevStatus|null|undefined) => {
            if(elev?.up){
                if(elev?.move){
                    return(<UpAnimation />)

               }else{
                    return(
                        <img src={"/images/up01.png"} width="50" height="50" />
                    )    
                }
            }else{
                if(elev?.down){
                    if(elev?.move){
                        return(
                            <DownAnimation/>
                         )
                             
                   }else{
                        return(
                            <img src={"/images/down01.png"} width="50" height="50" />
                        )    
                    }
                }else{
                    <div  style={{width:50,height:50}} />
                }
                
            }
      }
   
        
      const getDoorStatus = (elev:T_elevStatus|null|undefined) => {
        if(elev?.doorOpen){
                return( <img src={"/images/close_ani00.png"} width="50" height="50" />)    
        }else{
            return( <img src={"/images/open_ani00.png"} width="50" height="50" />)    
        }
       }



     return(
        <MyMDBox mb={1} display="flex"
        >
        <Card 
         style={{backgroundColor:"#344767",cursor: cursorType }}
         onClick={()=>{
            nav.pushParam('/elevator/monitor',{elevator:elevator});
            //handleClick(elevatorNo);}
             }
        }
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
         >
        <MyMDBox display="flex" pt={1} px={3} flexDirection="column" >
        <MyMDBox
          variant="gradient"
          bgColor="info"
          color="dark"
          coloredShadow="white"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContens="center"
          width="10rem"
          height="3rem"
          mt={-3}
        >
        <MyMDTypography variant="h6" color="white"
            onclick={()=>{
                console.log("click",elev);
            }}
          alignContens="center">{props.elevator?.installationPlace}</MyMDTypography>
        </MyMDBox>
        <MyMDBox
          pt={3}
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        <MyMDBox
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
           <MyMDBox>
          <MyMDTypography variant="h1" color="warning">{(floor?.find(item => item.floor == elev?.cfloor)?.display)?floor?.find(item => item.floor == elev?.cfloor)?.display:"0"}</MyMDTypography>
          </MyMDBox>  
          <div style={{width:50,height:50}}>{getUpStatus(elev)}</div>
          </MyMDBox>
        <MyMDBox
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
         >
             <div>{getDoorStatus(elev)}</div>
          </MyMDBox>
        </MyMDBox>
        <MyMDBox
          variant="gradient"
          bgColor="white"
          color="dark"
          coloredShadow="white"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContens="center"
          width="10rem"
          height="2rem"
          mt={1}
          mb={2}
        >
            {getDriverStatus(elev)}
        </MyMDBox>
           </MyMDBox>
        </Card>            
        </MyMDBox>
     )

}

function DownAnimation(){
    const [index, setIndex] = useState(0);
    const images = ["/images/down01.png","/images/down02.png","/images/down03.png","/images/down04.png","/images/down05.png","/images/down06.png"];

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((index) => index+1);
        }, 200);
        return () => clearInterval(interval);
        
      }, []);
      if (index >= images.length) {
        setIndex(0);
      }
   
    return (
        <img src={images[index]} width="50" height="50" />  
      );
}


function UpAnimation(){
    const [index, setIndex] = useState(0);
    const images = ["/images/up01.png","/images/up02.png","/images/up03.png","/images/up04.png","/images/up06.png"];

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((index) => index+1);
        }, 200);
        return () => clearInterval(interval);
        
      }, []);
      if (index >= images.length) {
        setIndex(0);
      }
   
    return (
        <img src={images[index]} width="50" height="50" />  
      );
}

function OpenAnimation(){
    const [index, setIndex] = useState(0);
    const images =  ["/images/open_ani00.png","/images/open_ani01.png","/images/open_ani02.png","/images/open_ani03.png"];

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((index) => index+1);
        }, 200);
        return () => clearInterval(interval);
        
      }, []);
      if (index >= images.length) {
        setIndex(0);
      }
   
    return (
        <img src={images[index]} width="50" height="50" />
      );
}


function CloseAnimation(){
    const [index, setIndex] = useState(0);
    const images =  ["/images/close_ani00.png","/images/close_ani01.png","/images/close_ani02.png","/images/close_ani03.png"];

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((index) => index+1);
        }, 200);
        return () => clearInterval(interval);
        
      }, []);
      if (index >= images.length) {
        setIndex(0);
      }
   
    return (
        <img src={images[index]} width="50" height="50" />  
      );
}




export default ElevStatus;