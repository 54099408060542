import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import '../../css/style.css';
import { useCallback, useEffect, useState } from "react";
import MyMDSelect, { T_item } from "../../components/MyMDSelect";
import apis from "../../apis";
import MyEdit from "../../examples/MyEdit";
import MyMDBox from "../../components/MyMDBox";
import MyMDButton from "../../components/MyMDButton";
import useNav from "../../uses/useNav";
import { T_message } from "../../apis/message";
import MyMDTypography from "../../components/MyMDTypography";


function MessageCreate(){
   const nav = useNav();
   const [itemList,setItemList] = useState<T_item[]>([{name:"all",value:"all"}])
   const [selCate,setSelCate] = useState<string>("all");
   const [insertData,setInsertData] = useState<T_message>();
  
   const insertMessage = async()=>{
      if(!insertData?.category){
       alert("카테고리를 선택하세요")
       return;
      }
      if(!insertData?.title){
         alert("제목을 입력하여 주십시요")
         return;
        }
      if(!insertData?.category){
         alert("내용을 입력 하여 주십시요")
         return;
        }        
      if(confirm("정말로 메세지를 발송 하시겠습니까?")){
         const result = await apis.message.insertMst(insertData)
         console.log(result);
         if(result.isSuccess){
            alert("저장이 되었습니다.");
            nav.push("/message");
         }else{
            alert(result.msg);
         }
         }
   }

  const getCateList = useCallback(async () => {
   try {
     console.log("start");
     const result = await apis.code.getCodeDtlList({pCode:"MT"})
     if(result.isSuccess && result.list){
       console.log(result.list)
       const updatedItemList = result.list.map((item) => ({
         value: item.code ? item.code : "all",
         name: item.codeDtlNm ? item.codeDtlNm : "",
       }));
         setItemList(updatedItemList);
       //  console.log(elevator);
     } else {
     }
   } catch (err: any) {
     console.log(err.message);
   }
 }, []);


   useEffect(()=>{
      getCateList();
   },[])

    return(
        <DashboardLayout>
        <DashboardNavbar />
        <MyEdit 
          header={{
            title: "메시지 등록",
          }}
        >
         <></>
         <MyMDBox pt={1} pl={1} pr={1} borderRadius="md" bgColor="#FFFFFF">
         <MyMDBox p={5}  pt={5}  pb={2} >
             <MyMDTypography ml={1} variant="h6">
             </MyMDTypography>         
            <table className="input">
                  <colgroup>
                     <col width="16%" />
                     <col width="" />
                  </colgroup>
                  <tbody>
                  <tr>
                     <th >카테고리 *</th>
                     <td>
                     <MyMDSelect  itemList={itemList} value={insertData?.category}   onChange={(item)=>{
                           setInsertData({...insertData,category:item})
                          }} />
                     </td>
                  </tr>
                  <tr>
                     <th>제목 *</th>
                     <td>
                        <input
                           type="text"
                           name="board_title"
                           placeholder="제목을 입력해주세요."
                           style={{
                              width: '400px',
                           }}
                           value={insertData?.title}
                           onChange={(e)=>{

                                 console.log(e.target.value);
                                 setInsertData({...insertData,title:e.target.value})
                           }

                           }
                        />
                     </td>
                  </tr>
                  <tr>
                     <th>내용 *</th>
                     <td>
                        <textarea
                           style={{
                              resize: 'none',
                           }}
                           maxLength={1000}
                           className="write_board"
                           placeholder="최대 1000자이내로 입력해주세요."
                           name="board_contents"
                           value={insertData?.content}
                           onChange={(e)=>{

                                 console.log(e.target.value);
                                 setInsertData({...insertData,content:e.target.value})
                           }
                         }
                        />
                     </td>
                  </tr>
                  </tbody>
            </table>


            <MyMDBox m={1} justifyContent="center" display="flex">
                            <MyMDBox pr={1}>
                            <MyMDButton m={1}    variant="gradient"
                                color="secondary"
                                onClick={()=>{
                                  nav.goBack();
                                }}
                                >취소</MyMDButton>              
                                </MyMDBox>
                          <MyMDBox pr={1}>
                          <MyMDButton m={1} variant="gradient"
                            onClick={()=>{
                              insertMessage();
                            
                            }}
                            color="info">등록</MyMDButton>
                          </MyMDBox>
                          <MyMDBox pr={1}>
                          <MyMDButton m={1}   
                            color="warning">삭제</MyMDButton>
                          </MyMDBox>

            </MyMDBox>
            </MyMDBox>
            </MyMDBox>
            </MyEdit>
        </DashboardLayout>
    )

}



export default MessageCreate;