import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import '../../css/style.css';
import MyMDInput from "../../components/MyMDInput";
import { useUser } from "../../contexts/user";
import { useEffect, useState } from "react";
import formats from "../../modules/formats";
import { Button, TextField } from "@mui/material";
import MyMDButton from "../../components/MyMDButton";
import MyMDBox from "../../components/MyMDBox";
import apis from "../../apis";
import MyEdit from "../../examples/MyEdit";
import MyMDTypography from "../../components/MyMDTypography";
import { useProgress } from "../../contexts/ProgressContextProvider";



function NoticeCreate(){

   const { user } = useUser();
   const [totalPage, setTotalPage] = useState<null | number>(null);
   const today = new Date();
   const preMonth = today.setMonth(today.getMonth()-1);
   const [sdate, setSdate] = useState( formats.date(new Date(preMonth), "yyyy-mm-dd"));
   const [edate, setEdate] = useState( formats.date(new Date(), "yyyy-mm-dd"));

   const{showProgress,setShowProgress} = useProgress();

   const [content,setContent] = useState<string>();
   const [content1,setContent1] = useState<string>();

   const getList =  async() =>{
    const response  =    await apis.board.getNotice()
         setContent(response.contents)
         setContent1(response.contents2)
   }


   const updateNotice = async() => {
      if(window.confirm("정말로 수정 하시겠습니까?")){
         setShowProgress(true);
      const response = await apis.board.updateNotice({
         contents:content,
         contents2 : content1,
      })
       console.log(response)  
      if(response.isSuccess){
         setShowProgress(false);
         alert("수정이 완료 되었습니다.");
        }else{
         setShowProgress(false);
         alert("오류가 발생했습니다.");
        }
      
      }
   }



   useEffect(()=>{
      getList();
   },[]);


   
    return(
        <DashboardLayout>
        <DashboardNavbar />
          <MyEdit 
          header={{
            title: "공지사항",
          }}
        >
         <></>
         <MyMDBox pt={1} pl={1} pr={1} borderRadius="md" bgColor="#FFFFFF">
         <MyMDBox p={5}  pt={5}  pb={2} >
             <MyMDTypography ml={1} variant="h6">
             </MyMDTypography>         
               <table className="input">
                  <tbody>
                  <tr>
                     <th style={{width:'15%'}}>공지사항1</th>
                     <td>
                        <input
                           type="text"
                           name="board_title"
                           placeholder="공지사항을 입력 해주세요"
                           style={{
                              width: '100%',
                              borderRadius:0,
                              background:"#eeeeee"
                           }}

                           value={content}
                           onChange={(event)=>{
                              setContent(event.target.value);
                           }}
                        />
                     </td>
                  </tr>
                  <tr>
                     <th>공지사항2</th>
                     <td>
                        <input
                           type="text"
                           name="board_title"
                           placeholder="공지사항을 입력 해주세요"
                           style={{
                              width: '100%',
                              borderRadius:0,
                              background:"#eeeeee"
                           }}
                           value={content1}
                           onChange={(event)=>{
                              setContent1(event.target.value);
                           }}

                        />   
                     </td>
                  </tr>
                  </tbody>
               </table>
               <MyMDBox m={1} justifyContent="center" display="flex">
                          <MyMDBox pr={1}>
                          <MyMDButton m={1}   variant="gradient"
                           onClick={updateNotice}
                            color="info">수정</MyMDButton>
                          </MyMDBox>
            </MyMDBox>
               </MyMDBox>
               </MyMDBox>
            </MyEdit>
        </DashboardLayout>
    )

}



export default NoticeCreate;