/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

==========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MyMDBox from "../../components/MyMDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import { useCallback, useEffect, useState } from "react";
import MyMDTypography from "../../components/MyMDTypography";
import MyTable from "../../examples/MyTable";
import { T_check, T_checkGetListData, T_checkResult, T_checkStatusValue } from "../../apis/check";
import apis from "../../apis";
import useNav from "../../uses/useNav";
import { Navigate } from "react-router-dom";
import MyIcon from "../../components/MyIcon";
import formats from "../../modules/formats";
import MyMDInput from "../../components/MyMDInput";
import MyMDButton from "../../components/MyMDButton";
import MyMDSelect, { T_item } from "../../components/MyMDSelect";
import { useUser } from "../../contexts/user";
import { Typography } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { T_alarm, T_elevError } from "../../models/error";
import moment from "moment";
import server from "../../values/server";
import ImageModal from "../../layouts/imageModal/ImageModal";
// import { toast } from "react-toastify";

type T_selectCheckResult = {
  name: string;
  value: T_checkStatusValue;
};

function AlarmListLayout() {
  
  const nav = useNav();
  const pagesize = 10;
  const { user } = useUser();
  const isNavReplace = !nav.search;
  const page = nav.params.page;
  const today = new Date();
  const preMonth = today.setMonth(today.getMonth()-1);
  const [sdate, setSdate] = useState(nav.params.date || formats.date(new Date(preMonth), "yyyy-mm-dd"));
  const [edate, setEdate] = useState(nav.params.date || formats.date(new Date(), "yyyy-mm-dd"));
//  const [checkResult, setCheckResult] = useState(nav.params.checkResult || "all");
  const [alarmList, setAlarmList] = useState<T_alarm[] | null>(null);
  const [detailModalTarget, setDetailModalTarget] = useState<T_check | null>(null);
  const [statusModalTarget, setStatusModalTarget] = useState<T_check | null>(null);
  const [totalPage, setTotalPage] = useState<null | number>(null);
  const [total, setTotal] = useState<null | number>(null);
  const [groupList,setGroupList] = useState<T_item[]>([]);
  const [selGroup,setSelGroup] = useState("all");
  const [elevList,setElevList] = useState<T_item[]>([{value:"all",name:"전체"}]);
  const [selElev,setSelElev] = useState("all");
  const [imageModalTarget, setImageModalTarget] = useState<string[] | null>(null);

  const selectCheckResultList: T_selectCheckResult[] = [
    {
      value: "all",
      name: "전체",
    },
    {
      value: "0",
      name: "점검완료",
    },
    {
      value: "1",
      name: "점검중",
    },
  ];

  const [search,setSearch] = useState<T_checkGetListData>({
    page:page?Number(page):1,
    pagesize: 10,
    sdate:nav.params.date || formats.date(new Date(preMonth), "yyyy-mm-dd"),
    edate:nav.params.date || formats.date(new Date(), "yyyy-mm-dd"),
    groupCode:"all",
    elevatorNo:"all",
    status:"all",
  })
 

  
  const getElevatorList = useCallback(async () => {

    try {
      const result = await apis.elevator.getList(
        {
          page:1,
          pagesize:100,
          groupCode:search.groupCode?search.groupCode:"all"
        }
      );

      if (result.isSuccess) {
        const commImteList : T_item [] = [{value:"all",name:"전체"}];
        const updatedItemList : T_item [] = result.elevatorList.map((item) => ({
          value: item.elevatorNo ? item.elevatorNo : "",
          name: item.buldNm? item.buldNm : "",
        }));   
   //     setItemList(commImteList.concat(updatedItemList));
        setElevList(commImteList.concat(updatedItemList));
        console.log(result.msg);
      } else {
        console.log(result.msg);
      }
    } catch (err: any) {
    }
  }, [search.groupCode]);

  

  
  const getGroupList = async () => {
    try {
      console.log("start");
      const result = await apis.code.getCodeDtlList({pCode:"GR"})
      if(result.isSuccess && result.list){
        const commImteList : T_item [] = [{value:"all",name:"전체"}];
        const updatedItemList : T_item [] = result.list.map((item) => ({
          value: item.code ? item.code : "",
          name: item.codeDtlNm ? item.codeDtlNm : "",
        }));
  //      console.log(commImteList.concat(updatedItemList));
        setGroupList(commImteList.concat(updatedItemList));
         
      } else {
      }
    } catch (err: any) {
      console.log(err.message);
    }
  }

  const getAlarmList = useCallback(async () => {

    try {
      console.log("alarm search:",search);
      const result = await apis.elevator.getElevAlarmList(search);
      console.log(result)
      if (result.isSuccess) {
        result.totalPage&&setTotalPage(result.totalPage);
        result.total&&setTotal(result.total);
        setAlarmList(result.data);
      } else {
        setAlarmList([]);
      }
    } catch (err: any) {
      setAlarmList([]);
      console.log(err.message);
    }
  }, [search]);

  useEffect(() => {
     
    
    const init = async () => {
      await getAlarmList();
    };
    init();
  }, [search]);

  useEffect(()=>{
    setSearch({...search,page:parseInt(nav.params.page)})
  },[nav.params.page])
  
  useEffect(()=>{
    getElevatorList();
  },[search.groupCode])
    
  useEffect(()=>{
    getGroupList();
  },[])


  if (!user) {
    return <Navigate replace to={`/signin`} />;
  } else if (isNavReplace) {
    return <Navigate replace to={`/alarm?page=1`} />;
  } else {
    return (
      <>
        <DashboardLayout>
          <DashboardNavbar />
          {alarmList && (
            <>
              <MyTable
                pagesize={pagesize}
                totalPage={totalPage}
                header={{
                  title: "알림 현황",
                }}
                filter={
                  <>
                    <MyMDInput
                      type="date"
                      value={search.sdate}
                      onChange={(evt: any) => {
                        const data = evt.target.value;
                        setSearch({...search,sdate:data})

                      }}
                    />
                    <Typography mx={2}>
                    -
                    </Typography>
                    
                    <MyMDInput
                      type="date"
                      value={search.edate}
                      onChange={(evt: any) => {
                          const data = evt.target.value;
                          setSearch({...search,edate:data})
                          //
                      }}
                    />

                    <MyMDBox
                      display="flex"
                      sx={{
                        flex: 1,
                        justifyContent: "flex-end",
                      }}
                    >

                  <MyMDBox ml={1} width={130}>
                      <MyMDSelect  itemList={groupList}  value={search.groupCode} onChange={(item)=>{
                                           // setSelGroup(item);  
                                           setSearch({...search,groupCode:item,elevatorNo:"all"});
                                            }} />
                  </MyMDBox>
                  <MyMDBox ml={1} width={130}>
                      <MyMDSelect  itemList={elevList}  value={search.elevatorNo} onChange={(item)=>{
                                           // setSelElev(item);
                                           setSearch({...search,elevatorNo:item});
                                            }} />
                  </MyMDBox>
                    <MyMDBox ml={1}>                  
                      <MyMDButton
                        component="a"
                        rel="noreferrer"
                        variant="gradient"
                        color="light"
                        href="#"
                        onClick={(evt: any) => {
                          evt.preventDefault();

                          getAlarmList();  
                        }}
                      >
                        조회
                      </MyMDButton>
                      </MyMDBox>
                    </MyMDBox>
                  </>
                }
                table={(() => {
                  type column =
                    | "no"
                    | "그룹"
                    | "엘리베이터"
                    | "제목"
                    | "내용"
                    | "일시"
                    | "비고";
                  type low = {
                    [name in column]: JSX.Element;
                  };
                  const columnList: column[] = [
                    "no",
                    "그룹",
                    "엘리베이터",
                    "제목",
                    "내용",
                    "일시",
                    "비고",
                  ];

                  return {
                    columns: columnList.map((column) => {
                      let width = "";
                      let align = "left";
                      switch (column) {
                        case "no": {
                          width = "5%";
                          align = "left";
                          break;
                        }
                        case "그룹": {
                          width = "5%";
                          break;
                        }
                        case "엘리베이터": {
                          width = "12%";
                          align = "left";
                          break;
                        }
                        case "제목": {
                          width = "12%";
                          align = "left";
                          break;
                        }
                        case "내용": {
                          width = "12%";
                          align = "left";
                          break;
                        }
                        case "일시": {
                          width = "7%";
                          align = "left";
                          break;
                        }
                        case "비고": {
                          width = "7%";
                          align = "left";
                          break;
                        }
                        // case "비고": {
                        //   width = "21%";
                        //   break;
                        // }
                      }
                      return {
                        Header: column,
                        accessor: column,
                        width,
                        align,
                      };
                    }),
                    rows: alarmList.map((alarm, idx) => {
                      const row: low = {
                        no: (
                          <MyMDTypography
                            display="block"
                            variant="caption"
                            color="text"
                            fontWeight="regular"
                          >  {Number(total)-(pagesize*(Number(page)-1))-idx}
                          </MyMDTypography>
                        ),
                        ["일시"]: (
                          <MyMDTypography
                            display="block"
                            variant="caption"
                            color="text"
                            fontWeight="medium"
                          >{formats.date(alarm.insDate, "yyyy-mm-dd HH:MM:ss")}                         
                          </MyMDTypography>
                        ),
                        ["그룹"]: (
                          <MyMDTypography
                            onClick={(evt: any) => {
                              evt.preventDefault();
                              nav.push(`/group/0`);
                            }}
                            href="#"
                            display="block"
                            component="a"
                            variant="caption"
                            fontWeight="medium"
                          >
                            {alarm.elevinfo&&alarm.elevinfo.groupinfo?.codeDtlNm}
                          </MyMDTypography>
                        ),
                        ["엘리베이터"]: (
                  
                            <MyMDTypography
                              display="block"
                              variant="caption"
                              fontWeight="medium"
                              mb={1}
                            >{alarm.elevinfo&&alarm.elevinfo.buldNm}-{alarm.elevinfo&&alarm.elevinfo.installationPlace}                              
                            </MyMDTypography>
                        ),

                        ["제목"]: (
                          <MyMDTypography
                            display="block"
                            variant="caption"
                            fontWeight="medium"
                          >{alarm.title}
                          </MyMDTypography>
                        ),
                        ["내용"]: (
                        <MyMDTypography
                            display="block"
                            variant="caption"
                            fontWeight="medium"
                          > {alarm.type=="I"? 
                          <MyMDBox
                          component="a"
                          alignItems="center"
                          p={1}
                          href="#"
                          key={idx}
                          onClick={ () =>{

                            const images :string[]=[];
                             alarm.files?.map((value,idx)=>{
                                    images.push(server.url+"/upload/"+value.fileUrl);
                             }) 

                            setImageModalTarget(images);
                         //      onWindowOpen(file1.fileUrl)
                           }
                          }
                         //   evt.preventDefault();
                         
                        >
                          <div>
                          <img src={server.url+"/upload/"+alarm.content} alt="Image" width={100} height={100} />
                        </div>
                        </MyMDBox>
                        :alarm.content}

                          </MyMDTypography>                                
                        ),
                        ["비고"]: <></>,
                      };
                      return row;
                    }),
                  };
                })()}
              />
              <Footer />
            </>
          )}
              <ImageModal
      imageModalTarget={imageModalTarget}
      setImageModalTarget={setImageModalTarget}
      />
        </DashboardLayout>
      </>
    );
  }
}

export default AlarmListLayout;
