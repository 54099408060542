import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import { useUser } from "../../contexts/user";
import useNav from "../../uses/useNav";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MyMDBox from "../../components/MyMDBox";
import MyMDTypography from "../../components/MyMDTypography";
import { useCallback, useEffect, useState } from "react";
import { T_admin } from "../../contexts/user";
import MyMDButton from "../../components/MyMDButton";
import apis from "../../apis";
import MyEdit from "../../examples/MyEdit";
import PassChangeModal from "./PassChangeModal";
import MyMDInput from "../../components/MyMDInput";
import { TextField } from "@mui/material";


function AdminInfo(){
    const nav = useNav();
    const { user } = useUser();
    const state = nav.state as {userid:string}
    const id = state.userid;
    const [admin,setAdmin] =useState<T_admin>({userId:"",userNm:"",userEtc:"",userPart:""});
    const [isInit, setIsInit] = useState(false);
    const isCreate = nav.pathname.includes("/user/admin/create");
    const [isPass,setIsPass] = useState(false);

  const getUser = useCallback(async () => {
    try {
        console.log("start")
      const result = await apis.user.getAdminInfo({
        userId:id,
      });

      console.log("result:",result);
      if (result.isSuccess && result.user) {
        const user = result.user;
        setAdmin(result.user);
      } else {
        console.log(result.msg);
      }
    } catch (err: any) {
      console.log(err.message);
    }
  }, [id]);

    const deleteAdmin = async() =>{

            const result = await apis.user.deleteAdmin({
                userId:id
            }
            )
            if(result.isSuccess){
                alert(result.msg);
                nav.push("/admin");    
            }else{
                alert(result.msg);
                return;
            }
    }

    const updateAdmin = async() =>{
      const result = await apis.user.insertAdmin(admin)
    if(result.isSuccess){
        alert("수정이 완료 되었습니다.");
      
    }else{
        alert(result.msg);
        return;
    }

    }
            
     useEffect(() => {
           if (!isInit) {
            const init = async () => {
                if (!isCreate) {
                await getUser();
                }
                setIsInit(true);
            };
            init();
            }
      }, [isCreate, getUser, isInit]);
            
    return(
<>
        <DashboardLayout>
        <DashboardNavbar />
        <MyEdit 
          header={{
            title: "관리자 정보",
          }}
        >
        <></>
       <MyMDBox pt={1} pl={1} pr={1} borderRadius="md" bgColor="#FFFFFF">
         <MyMDBox pr={1}  pt={1}  pb={2} >
             <MyMDTypography ml={1} variant="h6">
                관리자  정보
             </MyMDTypography>
        <table   className="myTable"
                    style={{
                      margin: 5,
                      tableLayout: "fixed",
                    }}
        >
        <tbody>
              <tr>
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 관리자아이디 </MyMDTypography></td>
              <td>
                  <MyMDTypography ml={1} variant="caption"  fontWeight="medium">{admin?.userId}</MyMDTypography>
              </td>
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption">관리자명</MyMDTypography></td>
              <td>
                  <MyMDInput value={admin?.userNm}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                     setAdmin({...admin,userNm:value})
                                }}
                                autoFocus
                                style={{width:'80%'}}
                            />
              </td>
              </tr>
              <tr>
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 파트명 </MyMDTypography></td>
              <td>  <MyMDInput value={admin?.userPart}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                     setAdmin({...admin,userPart:value})
                                }}
                                autoFocus
                                style={{width:'80%'}}
                            /></td>
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption">비밀번호</MyMDTypography></td>
              <td>

              <MyMDButton   m={1}   variant="gradient"
                           
                           color="info"
                           onClick={()=>{
                               setIsPass(true);
                           }}
                           >
                               비밀번호변경</MyMDButton>
              </td>
              </tr>
              <tr >
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 비고 </MyMDTypography></td>
              <td colSpan={3} style={{height:'100%',padding:10}}> <TextField
                 multiline
                 rows={4}
                 value={admin?.userEtc}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                     setAdmin({...admin,userEtc:value})
                                }}
                                autoFocus
                                style={{width:'100%'}}
                />
                </td>
              </tr>
        </tbody>
        </table>
        <>
        <MyMDBox m={1} justifyContent="center" display="flex">
                            <MyMDBox pr={1}>
                            <MyMDButton     variant="gradient"
                                color="secondary"
                                style={{zIndex:9999}}
                                onClick={()=>{
                                  nav.goBack();
                                }}
                                >목록</MyMDButton>              
                            </MyMDBox>
                          <MyMDBox pr={1}>
                          <MyMDButton m={1} variant="gradient"
                              onClick={()=>{
                                  if(confirm("정말로 수정하시겠습니까?")){
                                    updateAdmin();
                                  }
                              }}
                                                   color="info">수정</MyMDButton>
                          </MyMDBox>
                          <MyMDBox pr={1}>
                          <MyMDButton m={1}   
                            color="warning"
                            onClick={()=>{
                              if(confirm("정말로 삭제하시겠습니까?")){
                                deleteAdmin();
                              }
                          }}
                            >삭제</MyMDButton>
                          </MyMDBox>

        </MyMDBox>
        </>
        </MyMDBox>
        </MyMDBox>
        </MyEdit>
        <PassChangeModal
  onSetVisible={setIsPass}
  isVisible={isPass}
  data={{userId:id}}
/>

      </DashboardLayout>    
</>
    )
    
}

export default AdminInfo;