import MyMDBox from "../../components/MyMDBox";
import MyModal from "../../components/MyModal";
import MyMDTypography from "../../components/MyMDTypography";
import { useEffect, useState } from "react";
import MyIcon from "../../components/MyIcon";
import { T_admin } from "../../contexts/user";
import apis from "../../apis";
import MyMDInput from "../../components/MyMDInput";
import MyMDButton from "../../components/MyMDButton";
import { Typography } from "@mui/material";
import { send } from "process";


type T_passChangeProps ={
    isVisible : boolean 
    onSetVisible : (visible:boolean)=> void;
    data : T_admin;
}
type T_adminInput=  {
    userId: string;
    userPass? : string;
    userPass1? : string;
    passCheck? : boolean;
}


function PassChangeModal({isVisible,onSetVisible,data}:T_passChangeProps){


        const [sendData,setSendData] = useState<T_adminInput>({userId:"",passCheck:true});
        const updatePass = async()=>{
            const result = apis.user.insertAdmin(data)

        } 

        const initialData =()=>{
            setSendData({
                userId:"",
                userPass:"",
                userPass1:"",
                passCheck:true,
            })
        }
    
        const handlePass = async() =>{
           
              if(sendData.userPass == "" || sendData.userPass == null){
                alert("비밀번호를 입력하세요")
              }
              if(confirm("정말로 비밀번호를 수정하시겠습니까?")){
              const result = await apis.user.insertAdmin(sendData)
                   if(result.isSuccess){
                    alert("수정이 완료 되었습니다.")
                    onSetVisible(false);
                   }else{
                     alert("수정중 오류가 발생했습니다.")
                   }
              }else{
                return;
              }
        }


        useEffect(()=>{
         data? setSendData({userId:data.userId,passCheck:true}):"";
        },[data,isVisible])


    return(
      <>
        <MyModal 
        isVisible={isVisible}
        setIsVisible={() => {
            initialData();
            onSetVisible(false);
         }}
        style={{
          width: 400,
          height:280,
          maxWidth: window.innerWidth - 100,
          zIndex:0
        //  height: window.innerHeight - 100,
        }}
        >
     <>
        <MyMDBox pt={5} pb={5}>
          <MyMDTypography ml={5} variant="h5">
            비밀번호 변경
          </MyMDTypography>
          <MyMDBox pt={1} pl={5} pr={5}>
            <MyMDBox
              pr={1}
              pb={2}
            >
                      <MyMDTypography variant="caption" sx={{color:'warning.main'}}  fontWeight="regular">
                      {!sendData?.passCheck?"* 비밀번호가 일치 하지 않습니다.":""}
                      </MyMDTypography>
          
              <table
                className="myTable"
                style={{
                  marginTop: 5,
                }}
              >
                <thead></thead>
                <tbody>
                <tr>
                    <td
                     style={{background:"#eeeeee"}}
                    >  <Typography variant="caption" color="success" >
                          비밀번호
                          </Typography>
                    </td>
                    <td>
                    <MyMDInput value={sendData?.userPass}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                    if(sendData?.userPass1===value ) { 
                                         setSendData({...sendData,userPass:value,passCheck:true})
                                    }
                                    else {
                                        setSendData({...sendData,userPass:value,passCheck:false})
                                     }

                                }}
                                autoFocus
                                type="password"
                                style={{padding:5}}
                      />                        
                    </td>
                  </tr>
                  <tr >
                    <td style={{background:"#eeeeee"}}> <MyMDTypography variant="caption" fontWeight="regular">
                          비밀번호확인
                          </MyMDTypography></td>
                    <td>
                    <MyMDInput value={sendData?.userPass1}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                    if(sendData?.userPass===value ) { 
                                      setSendData({...sendData,userPass1:value,passCheck:true})
                                    }
                                    else{
                                     setSendData({...sendData,userPass1:value,passCheck:false})
                                     }

                                }}
                                autoFocus
                                type="password"
                                style={{padding:5}}
                            />                        

                    </td>
                  </tr>
                </tbody>
              </table>
              <MyMDButton  variant="gradient"
                            component="a"
                            size="small"
                            style={{width:'100%',marginTop:15}}
                            color="info"

                            onClick={()=>{
                              if(!sendData.passCheck){
                                alert("비밀번호가 일치 하지 않습니다.")
                                return;
                              }
                                handlePass();
                            }}
                            >
                                비밀번호변경</MyMDButton>
            </MyMDBox>
            <MyMDBox
              p={1}
              onClick={() => {

                initialData();
                onSetVisible(false);
              }}
              sx={{
                position: "absolute",
                cursor: "pointer",
                top: 30,
                right: 30,
              }}
            >
              <MyIcon name="AiOutlineClose" />
            </MyMDBox>
          </MyMDBox>
        </MyMDBox>
      </>
      </MyModal>
    </>          
    )

}

export default PassChangeModal;