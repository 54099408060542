/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

==========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MyMDBox from "../../components/MyMDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import { useCallback, useEffect, useState } from "react";
import MyMDTypography from "../../components/MyMDTypography";
import MyTable from "../../examples/MyTable";
import { T_check, T_checkGetListData, T_checkResult, T_checkStatus, T_checkStatusValue, T_checkgetStatusList2Data } from "../../apis/check";
import apis from "../../apis";
import useNav from "../../uses/useNav";
import { Navigate } from "react-router-dom";
import MyIcon from "../../components/MyIcon";
import formats from "../../modules/formats";
import MyMDInput from "../../components/MyMDInput";
import MyMDButton from "../../components/MyMDButton";
import MyMDSelect, { T_item } from "../../components/MyMDSelect";
import { useUser } from "../../contexts/user";
import { Typography } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { T_elevError } from "../../models/error";
import moment from "moment";
import { T_elevator } from "../../models/elevator";
import { useProgress } from "../../contexts/ProgressContextProvider";
// import { toast } from "react-toastify";

type T_selectCheckResult = {
  name: string;
  value: T_checkStatusValue;
};

function ContactPointListLayout() {
  
  const nav = useNav();
  console.log("nav:",nav);


  const pagesize = 50;
  const { user } = useUser();
  const isNavReplace = !nav.search;
  const page = nav.params.page;
  const state = nav.state as {elevator:T_elevator}
    const elevator = nav.state?state.elevator:"";

  

  const today = new Date();
  const preMonth = today.setMonth(today.getMonth()-1);
  const [sdate, setSdate] = useState(nav.params.date || formats.date(new Date(preMonth), "yyyy-mm-dd"));
  const [edate, setEdate] = useState(nav.params.date || formats.date(new Date(), "yyyy-mm-dd"));
//  const [checkResult, setCheckResult] = useState(nav.params.checkResult || "all");
  const [errorList, setErrorList] = useState<T_elevError[] | null>(null);
  const [detailModalTarget, setDetailModalTarget] = useState<T_check | null>(null);
  const [statusModalTarget, setStatusModalTarget] = useState<T_check | null>(null);
  const [totalPage, setTotalPage] = useState<null | number>(null);
  const [total, setTotal] = useState<null | number>(null);
  const [groupList,setGroupList] = useState<T_item[]>([]);
  const [selGroup,setSelGroup] = useState("all");
  const [elevList,setElevList] = useState<T_item[]>([{value:"all",name:"전체"}]);
  const [selElev,setSelElev] = useState(elevator?elevator.elevatorNo:"all");
  const [contactPointList, setContactPointList] = useState<T_checkStatus[] | null>(null);
  
  const {showProgress,setShowProgress} = useProgress();

  const selectCheckResultList: T_selectCheckResult[] = [
    {
      value: "all",
      name: "전체",
    },
    {
      value: "0",
      name: "점검완료",
    },
    {
      value: "1",
      name: "점검중",
    },
  ];

  const [search,setSearch] = useState<T_checkgetStatusList2Data>({
    page:page?Number(page):1,
    pagesize: pagesize,
    wdate:nav.params.date || formats.date(new Date(), "yyyy-mm-dd"),
    elevatorNo:selElev,
  })
 
  const getContactList = useCallback(async () => {
    setShowProgress(true);
    try {
      console.log("contact start:",search)
      const result = await apis.check.getStatusList2(search);
      
      if (result.isSuccess) {
        result.totalPage&&setTotalPage(result.totalPage);
        result.total&&setTotal(result.total);
        console.log("contact end",result.checkStatusList)
        setShowProgress(false);
        setContactPointList(result.checkStatusList);
      } else {
        setShowProgress(false);
        setContactPointList([]);
      }
    } catch (err: any) {
      setShowProgress(false);
      setContactPointList([]);
      console.log(err.message);
    }
  }, [search]);
  
  const getElevatorList = useCallback(async () => {

    try {
      const result = await apis.elevator.getList(
        {
          page:1,
          pagesize:100,
          groupCode:search.groupCode?search.groupCode:"all"
        }
      );

      if (result.isSuccess) {
        const commImteList : T_item [] = [{value:"all",name:"전체"}];
        const updatedItemList : T_item [] = result.elevatorList.map((item) => ({
          value: item.elevatorNo ? item.elevatorNo : "",
          name: item.installationPlace? item.installationPlace : "",
        }));   
   //     setItemList(commImteList.concat(updatedItemList));
        setElevList(commImteList.concat(updatedItemList));
        console.log(result.msg);
      } else {
        console.log(result.msg);
      }
    } catch (err: any) {
    }
  }, [search]);

  



  useEffect(()=>{
    setSearch({...search,page:parseInt(nav.params.page)})
  },[nav.params.page])

  useEffect(() => {
     
    const init = async () => {
     console.log("contact init")
      await getContactList();
    };
    init();
    console.log("contact search")
  }, [search]);

  useEffect(()=>{
    getElevatorList();
  },[])
    
  // useEffect(()=>{
  //   getGroupList();
  // },[])


  if (!user) {
    return <Navigate replace to={`/signin`} />;
  } else if (isNavReplace) {
    return <Navigate replace to={`/elevator/blackbox?page=1`} />;
  } else {
    return (
      <>
        <DashboardLayout>
          <DashboardNavbar />
          {contactPointList && (
            <>
              <MyTable
                pagesize={pagesize}
                totalPage={totalPage}
                header={{
                  title: "접점정보리스트",
                }}
                filter={
                  <>
                    <MyMDInput
                      type="date"
                      value={search.wdate}
                      onChange={(evt: any) => {
                        const data = evt.target.value;
                        setSearch({...search,wdate:data})

                      }}
                    />
                    <MyMDBox
                      display="flex"
                      sx={{
                        flex: 1,
                        justifyContent: "flex-end",
                      }}
                    >

                         <MyMDBox ml={1} width={130}>
                      <MyMDSelect  itemList={elevList}  value={search.elevatorNo} onChange={(item)=>{
                                           // setSelElev(item);
                                           setSearch({...search,elevatorNo:item});
                                            }} />
                  </MyMDBox>
                    <MyMDBox ml={1}>                  
                      <MyMDButton
                        component="a"
                        rel="noreferrer"
                        variant="gradient"
                        color="light"
                        href="#"
                        onClick={(evt: any) => {
                          evt.preventDefault();
                          getContactList();  
                        }}
                      >
                        조회
                      </MyMDButton>
                      </MyMDBox>
                    </MyMDBox>
                  </>
                }
                table={(() => {
                  type column =
                  | "date"
                  | "time"
                  | "a"
                    | "b"
                    | "c"
                    | "d"
                    | "e"
                    | "f"
                    | "g"
                    | "h"
                    | "f"
                    | "g"
                    | "h"
                    | "i"
                    | "j"
                    | "k"
                    | "l"
                    | "m"
                    | "n"
                    | "o"
                    | "p"
                    | "q"
                    | "r"
                    | "s"
                    | "t"
                    | "u"
                    | "v"
                    | "w"
                    | "x"
                    ;
                  type low = {
                    [name in column]: JSX.Element;
                  };
                  const columnList: column[] = [
                    "date",
                    "time",
                    "a",
                    "b",
                    "c",
                    "d",
                    "e",
                    "f",
                    "g",
                    "h",
                    "i",
                    "j",
                    "k",
                    "l",
                    "m",
                    "n",
                    "o",
                    "p",
                    "q",
                    "r",
                    "s",
                    "t",
                    "u",
                    "v",
                    "w",
                    "x",
                  ];

                  return {
                    columns: columnList.map((column) => {
                      let width = "";
                      let align = "left";
                      switch (column) {
                        case "date": {
                          width = "5%";
                          break;
                        }
                        case "time": {
                          width = "5%";
                          align = "left";
                          break;
                        }
                        
                        case "a": {
                          align = "center";
                          break;
                        }
                        case "a": {
                          align = "center";
                          break;
                        }
                        case "b": {
                          align = "center";
                          break;
                        }
                        case "c": {
                          align = "center";
                          break;
                        }
                        case "d": {
                          align = "center";
                          break;
                        }
                        case "e": {
                          align = "center";
                          break;
                        }
                        case "f": {
                          align = "center";
                          break;
                        }
                        case "g": {
                          align = "center";
                          break;
                        }
                        case "h": {
                          align = "center";
                          break;
                        }
                        case "i": {
                          align = "center";
                          break;
                        }
                        case "j": {
                          align = "center";
                          break;
                        }
                        case "k": {
                          align = "center";
                          break;
                        }
                        case "l": {
                          align = "center";
                          break;
                        }
                        case "m": {
                          align = "center";
                          break;
                        }
                        case "n": {
                          align = "center";
                          break;
                        }
                        case "o": {
                          align = "center";
                          break;
                        }
                        case "p": {
                          align = "center";
                          break;
                        }
                        case "q": {
                          align = "center";
                          break;
                        }
                        case "r": {
                          align = "center";
                          break;
                        }
                        case "s": {
                          align = "center";
                          break;
                        }
                        case "t": {
                          align = "center";
                          break;
                        }
                        case "u": {
                          align = "center";
                          break;
                        }
                        case "v": {
                          align = "center";
                          break;
                        }
                        case "w": {
                          align = "center";
                          break;
                        }
                        case "x": {
                          align = "center";
                          break;
                        }



                        // case "비고": {
                        //   width = "21%";
                        //   break;
                        // }
                      }
                      return {
                        Header: column,
                        accessor: column,
                        width,
                        align,
                      };
                    }),
                    rows: contactPointList.map((point, idx) => {
                      const row: low = {
                        ["date"]: (
                          <MyMDTypography
                            display="block"
                            variant="caption"
                            color="text"
                            fontWeight="medium"
                          >{point.wdate}                         
                          </MyMDTypography>
                        ),
                        ["time"]: (
                          <MyMDTypography
                            onClick={(evt: any) => {
                              evt.preventDefault();
                              nav.push(`/group/0`);
                            }}
                            href="#"
                            display="block"
                            component="a"
                            variant="caption"
                            fontWeight="medium"
                          >
                            {point.wtime}
                          </MyMDTypography>
                        ),
                        ["a"]: (
                  
                            <MyMDTypography
                              display="block"
                              variant="caption"
                              fontWeight="medium"
                              mb={1}
                            >{point.p1==1?'O':'X'}                              
                            </MyMDTypography>
                        ),

                        ["b"]: (
                          <MyMDTypography
                            display="block"
                            variant="caption"
                            fontWeight="medium"
                          >{point.p2==1?'O':'X'}
                          </MyMDTypography>
                        ),
                        ["c"]: (
                          <MyMDTypography
                              display="block"
                              variant="caption"
                              fontWeight="medium"
                            >{point.p3==1?'O':'X'}
                            </MyMDTypography>                                
                          ),
                        ["d"]: (
                            <MyMDTypography
                                display="block"
                                variant="caption"
                                fontWeight="medium"
                              >{point.p4==1?'O':'X'}
                              </MyMDTypography>                                
                            ),
                         ["e"]: (
                              <MyMDTypography
                                  display="block"
                                  variant="caption"
                                  fontWeight="medium"
                                >{point.p5==1?'O':'X'}
                                </MyMDTypography>                                
                              ),
                              ["f"]: (
                                <MyMDTypography
                                    display="block"
                                    variant="caption"
                                    fontWeight="medium"
                                  >{point.p6==1?'O':'X'}
                                  </MyMDTypography>                                
                                ),
                                ["g"]: (
                                  <MyMDTypography
                                      display="block"
                                      variant="caption"
                                      fontWeight="medium"
                                    >{point.p7==1?'O':'X'}
                                    </MyMDTypography>                                
                                  ),
                                  ["h"]: (
                                    <MyMDTypography
                                        display="block"
                                        variant="caption"
                                        fontWeight="medium"
                                      >{point.p8==1?'O':'X'}
                                      </MyMDTypography>                                
                                    ),
                                    ["i"]: (
                                      <MyMDTypography
                                          display="block"
                                          variant="caption"
                                          fontWeight="medium"
                                        >{point.p9==1?'O':'X'}
                                        </MyMDTypography>                                
                                      ),
                                      ["j"]: (
                                        <MyMDTypography
                                            display="block"
                                            variant="caption"
                                            fontWeight="medium"
                                          >{point.p10==1?'O':'X'}
                                          </MyMDTypography>                                
                                        ),
                                        ["k"]: (
                                          <MyMDTypography
                                              display="block"
                                              variant="caption"
                                              fontWeight="medium"
                                            >{point.p11==1?'O':'X'}
                                            </MyMDTypography>                                
                                          ),
                                          ["l"]: (
                                            <MyMDTypography
                                                display="block"
                                                variant="caption"
                                                fontWeight="medium"
                                              >{point.p12==1?'O':'X'}
                                              </MyMDTypography>                                
                                            ),
                                            ["m"]: (
                                              <MyMDTypography
                                                  display="block"
                                                  variant="caption"
                                                  fontWeight="medium"
                                                >{point.p13==1?'O':'X'}
                                                </MyMDTypography>                                
                                              ),
                                              ["n"]: (
                                                <MyMDTypography
                                                    display="block"
                                                    variant="caption"
                                                    fontWeight="medium"
                                                  >{point.p14==1?'O':'X'}
                                                  </MyMDTypography>                                
                                                ),
                                                ["o"]: (
                                                  <MyMDTypography
                                                      display="block"
                                                      variant="caption"
                                                      fontWeight="medium"
                                                    >{point.p15==1?'O':'X'}
                                                    </MyMDTypography>                                
                                                  ),
                                                  ["p"]: (
                                                    <MyMDTypography
                                                        display="block"
                                                        variant="caption"
                                                        fontWeight="medium"
                                                      >{point.p16==1?'O':'X'}
                                                      </MyMDTypography>                                
                                                    ),
                                                    ["q"]: (
                                                      <MyMDTypography
                                                          display="block"
                                                          variant="caption"
                                                          fontWeight="medium"
                                                        >{point.p17==1?'O':'X'}
                                                        </MyMDTypography>                                
                                                      ),
                                                      ["r"]: (
                                                        <MyMDTypography
                                                            display="block"
                                                            variant="caption"
                                                            fontWeight="medium"
                                                          >{point.p18==1?'O':'X'}
                                                          </MyMDTypography>                                
                                                        ),
                                                        ["s"]: (
                                                          <MyMDTypography
                                                              display="block"
                                                              variant="caption"
                                                              fontWeight="medium"
                                                            >{point.p19==1?'O':'X'}
                                                            </MyMDTypography>                                
                                                          ),
                                                          ["t"]: (
                                                            <MyMDTypography
                                                                display="block"
                                                                variant="caption"
                                                                fontWeight="medium"
                                                              >{point.p20==1?'O':'X'}
                                                              </MyMDTypography>                                
                                                            ),
                                                            ["u"]: (
                                                              <MyMDTypography
                                                                  display="block"
                                                                  variant="caption"
                                                                  fontWeight="medium"
                                                                >{point.p21==1?'O':'X'}
                                                                </MyMDTypography>                                
                                                              ),
                                                              ["v"]: (
                                                                <MyMDTypography
                                                                    display="block"
                                                                    variant="caption"
                                                                    fontWeight="medium"
                                                                  >{point.p22==1?'O':'X'}
                                                                  </MyMDTypography>                                
                                                                ),
                                                                ["w"]: (
                                                                  <MyMDTypography
                                                                      display="block"
                                                                      variant="caption"
                                                                      fontWeight="medium"
                                                                    >{point.p23==1?'O':'X'}
                                                                    </MyMDTypography>                                
                                                                  ),
                                                                  ["x"]: (
                                                                    <MyMDTypography
                                                                        display="block"
                                                                        variant="caption"
                                                                        fontWeight="medium"
                                                                      >{point.p24==1?'O':'X'}
                                                                      </MyMDTypography>                                
                                                                    ),
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        

                                  };
                      return row;
                    }),
                  };
                })()}
              />
              <Footer />
            </>
          )}
        </DashboardLayout>
      </>
    );
  }
}

export default ContactPointListLayout;
