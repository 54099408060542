import useNav from "../../uses/useNav";
import MyMDBox from "../../components/MyMDBox";
import MyMDTypography from "../../components/MyMDTypography";
import Footer from "../../examples/Footer";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import MyTable from "../../examples/MyTable";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { useCallback, useEffect, useState } from "react";
import { useUser } from "../../contexts/user";
import apis from "../../apis";
import { T_admin } from "../../contexts/user";
import MyMDButton from "../../components/MyMDButton";
import MyModal from "../../components/MyModal";
import MyMDInput from "../../components/MyMDInput";
import MyIcon from "../../components/MyIcon";
import PassChangeModal from "./PassChangeModal";


function AdminList() {
    const nav = useNav();
    const isNavReplace = !nav.search;
    const { user } = useUser();
    const [userList,setUserList] =  useState<T_admin[] | null>(null);
    const [isPass,setIsPass] = useState(false);

    const [sendData,setSendData] = useState<T_admin>({userId:""});


    const getUserList = useCallback(async () => {
      
        try {
          const result = await apis.user.getAdminList();
            console.log(result);
          if (result.isSuccess) {
            setUserList(result.userList);
          } else {
            setUserList([]);
            console.log(result.msg);
          }
        } catch (err: any) {
          setUserList([]);
          console.log(err.message);
        }
      }, []);
    
      useEffect(() => {
        console.log("userList",userList)
        if (!userList) {
          const init = async () => {
            console.log("init")
            await getUserList();
          };
          init();
        }

      }, [userList, getUserList, isNavReplace]);
    

    return (
        <>
        <DashboardLayout>
          <DashboardNavbar />
          {userList && (
            <>
              <MyTable
                header={{
                  title: "관리자 관리",
                  rightComponent: (
                    <MyMDBox
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <MyMDButton
                        onClick={(evt: any) => {
                          evt.preventDefault();
                          nav.push(`/admin/create`);
                        }}
                        href="#"
                        component="a"
                        rel="noreferrer"
                        variant="gradient"
                        color="light"
                      >
                        등록
                      </MyMDButton>
                    </MyMDBox>
                  ),
                }}
                table={(() => {
                  type column = "no" | "아이디" | "관리자명" | "파트명" | "비고" | " ";
                  type low = {
                    [name in column]: JSX.Element;
                  };
                  const columnList: column[] = ["no", "아이디", "관리자명", "파트명","비고"," "];
  

                  return {
                    columns: columnList.map((column) => {
                      let width = "";
                      switch (column) {
                        case "no": {
                          width = "5%";
                          break;
                        }
                        case "아이디": {
                          width = "15%";
                          break;
                        }
                        case "관리자명": {
                          width = "15%";
                          break;
                        }
                        case "파트명": {
                          width = "15%";
                          break;
                        }
                        case "비고": {
                          width = "50%";
                          break;
                        }
                        case " ": {
                            width = "5%";
                            break;
                          }
                      }
  
                      return {
                        Header: column,
                        accessor: column,
                        width,
                      };
                    }),
                    rows: userList.map((user, idx) => {
                      const row: low = {
                        no: (
                          <MyMDTypography
                            display="block"
                            variant="caption"
                            color="text"
                            fontWeight="regular"
                          >
                            {userList.length - idx}
                          </MyMDTypography>
                        ),
                        ["아이디"]: (
                          <MyMDBox
                            onClick={(evt: any) => {
                              evt.preventDefault();
                              nav.pushParam(`/admin/info`,{userid:user.userId});
                            }}
                            href="#"
                            component="a"
                            alignItems="center"
                          >
                            <MyMDTypography
                              display="block"
                              variant="caption"
                              fontWeight="medium"
                              mb={1}
                            >
                                {user.userId}
                            </MyMDTypography>
                            {/* <MyMDTypography
                              display="block"
                              variant="caption"
                              fontWeight="regular"
                              color="text"
                            >
                              {user.userId}
                            </MyMDTypography> */}
                          </MyMDBox>
                        ),
                        ["관리자명"]: (
                          <MyMDTypography
                            onClick={(evt: any) => {
                              evt.preventDefault();
                            }}
                            href="#"
                            component="a"
                            display="block"
                            variant="caption"
                            fontWeight="medium"
                          >
                            {user.userNm}
                          </MyMDTypography>
                        ),
                        ["파트명"]: (
                          <MyMDTypography variant="caption" color="text" fontWeight="regular">
                            {user.userPart}
                          </MyMDTypography>
                        ),
                        ["비고"]: (
                          <MyMDTypography
                            display="block"
                            variant="caption"
                            color="text"
                            fontWeight="medium"
                          >
                            {user.userEtc}
                          
                          </MyMDTypography>
                        ),
                        [" "]: (
                            <MyMDButton  variant="gradient"
                            component="a"
                            size="small"
                            m={10}
                            color="info"
                            onClick={()=>{
                                console.log(user)
                                setSendData(user);

                                setIsPass(true);
                            }}
                            >
                                비밀번호변경</MyMDButton>
                        ),
                      };

                      return row;
                    }),
                  };
                })()}
              />
              <Footer />
            </>
          )}
        </DashboardLayout>
            <PassChangeModal
                onSetVisible={setIsPass}
                isVisible={isPass}
                data={sendData}
            />
            </>
      );
}

export default AdminList