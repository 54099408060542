import { T_checkHist, T_elevator } from "../../../models/elevator";
import MyModal from "../../../components/MyModal";
import apis from "../../../apis";
import { useEffect, useState } from "react";
import MyTable from "../../../examples/MyTable";
import MyMDBox from "../../../components/MyMDBox";
import MyMDButton from "../../../components/MyMDButton";
import MyMDTypography from "../../../components/MyMDTypography";
import Footer from "../../../examples/Footer";
import MyIcon from "../../../components/MyIcon";

type T_CheckStatusModalProps = {
    statusModalTarget: T_elevator | null;
    setStatusModalTarget: (statusModalTarget: T_elevator | null) => void;
  };

  

function CheckHistory({ statusModalTarget, setStatusModalTarget }: T_CheckStatusModalProps){
    const [checkList,setCheckList] = useState<T_checkHist[]|null>(null);
    const getData = async () =>{

        
        const response = await apis.elevator.getApiCheck(statusModalTarget?.elevatorNo?statusModalTarget?.elevatorNo:"")
        if(response.isSuccess){
        setCheckList(response.list);
        }else{
            setCheckList(null);    
        }
    }

    useEffect(()=>{
        if(statusModalTarget!=null){    getData(); }
    },[statusModalTarget]);

    return(
        <MyModal
        isVisible={!!statusModalTarget}
        setIsVisible={() => {
          setStatusModalTarget(null);
        }}
        style={{
          width: 1000,
          maxWidth: window.innerWidth - 100,
          height: window.innerHeight - 100,
        }}
      >
        <>
            <>
                <MyMDBox pt={5} pb={5}>
                <MyMDTypography ml={5} variant="h5">
                  엘리베이터 검사이력
                </MyMDTypography>
                <MyMDBox pt={1} pl={5} pr={5}>
                  <MyMDBox
                    pr={1}
                    pt={4}
                    pb={2}
                    sx={{
                      height: window.innerHeight - 200,
                      overflowY: "scroll",
                    }}
                  >
                    <MyMDTypography variant="h6">기본 정보</MyMDTypography>
                    <table
                      className="myTable"
                      style={{
                        marginTop: 5,
                        tableLayout: "fixed",
                      }}
                    >
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              backgroundColor: "#eeeeee",
                            }}
                          >
                            <MyMDTypography color="text" variant="caption" fontWeight="regular">
                              건물 정보
                            </MyMDTypography>
                          </td>
                          <td colSpan={3}>
                            <MyMDTypography variant="caption" fontWeight="medium">
                              {statusModalTarget?.buldNm} /  {statusModalTarget?.address1}
                            </MyMDTypography>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              backgroundColor: "#eeeeee",
                            }}
                          >
                            <MyMDTypography color="text" variant="caption" fontWeight="regular">
                              승강기 고유번호
                            </MyMDTypography>
                          </td>
                          <td>
                            <MyMDTypography variant="caption" fontWeight="medium">
                              {statusModalTarget?.elevatorNo}
                            </MyMDTypography>
                          </td>
                          <td
                            style={{
                              backgroundColor: "#eeeeee",
                            }}
                          >
                            <MyMDTypography color="text" variant="caption" fontWeight="regular">
                              호기
                            </MyMDTypography>
                          </td>
                          <td>
                            <MyMDTypography variant="caption" fontWeight="medium">
                              {statusModalTarget?.areaNo}
                            </MyMDTypography>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              backgroundColor: "#eeeeee",
                            }}
                          >
                            <MyMDTypography color="text" variant="caption" fontWeight="regular">
                              승강기 종류
                            </MyMDTypography>
                          </td>
                          <td>
                            <MyMDTypography variant="caption" fontWeight="medium">
                            {statusModalTarget?.elvtrKindNm}
                            </MyMDTypography>
                          </td>
                          <td
                            style={{
                              backgroundColor: "#eeeeee",
                            }}
                          >
                            <MyMDTypography color="text" variant="caption" fontWeight="regular">
                              승강기 모델
                            </MyMDTypography>
                          </td>
                          <td>
                            <MyMDTypography variant="caption" fontWeight="medium">
                            {statusModalTarget?.elvtrModel}
                            </MyMDTypography>
                          </td>
                        </tr>
                        </tbody>
                        </table>
                    <MyMDBox
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    mt={5}
                      >
                    <MyMDTypography variant="h6">검사이력</MyMDTypography>
                    <MyMDBox
                      sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                    </MyMDBox>
                  </MyMDBox>
             
                    <table
                      className="myTable"
                      style={{
                        marginTop: 5,
                      }}
                    >
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              backgroundColor: "#eeeeee",
                              paddingLeft: 0,
                            }}
                            align="center"
                          >
                            <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            검사종류
                            </MyMDTypography>
                          </td>
                          <td
                            style={{
                              backgroundColor: "#eeeeee",
                            }}
                            align="center"
                          >
                            <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            검사일자
                            </MyMDTypography>
                          </td>
                          <td
                            style={{
                              backgroundColor: "#eeeeee",
                              paddingLeft: 0,
                            }}
                            align="center"
                          >
                            <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            운행유효기간
                            </MyMDTypography>
                          </td>
                          <td
                            style={{
                              backgroundColor: "#eeeeee",
                              paddingLeft: 0,
                            }}
                            align="center"
                          >
                            <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            검사기관
                            </MyMDTypography>
                          </td>
                          <td
                            style={{
                              backgroundColor: "#eeeeee",
                              paddingLeft: 0,
                            }}
                            align="center"
                          >
                            <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            합격유무
                            </MyMDTypography>
                          </td>
                        </tr>
                        {checkList!=null ? 
                            checkList.map((check,index) => {
                                return(
                                    <tr>
                                        <td> <MyMDTypography color="text" variant="caption" fontWeight="regular">
                                            {check.inspctKind}
                                            </MyMDTypography>
                                            </td>
                                            <td> <MyMDTypography color="text" variant="caption" fontWeight="regular">
                                            {check.inspctDt}
                                            </MyMDTypography>
                                            </td>
                                            <td> <MyMDTypography color="text" variant="caption" fontWeight="regular">
                                            {check.applcBeDt} -{check.applcEnDt} 
                                            </MyMDTypography>
                                            </td>
                                            <td> <MyMDTypography color="text" variant="caption" fontWeight="regular">
                                            {check.inspctInsttNm}
                                            </MyMDTypography>
                                            </td>
                                            <td> <MyMDTypography color="text" variant="caption" fontWeight="regular">
                                            {check.psexamYn}
                                            </MyMDTypography>
                                            </td>
                                    </tr>
                                )

                            })

                          : (
                            <tr><td colSpan={10}>
                            <MyMDTypography variant="h6"    align="center">
                              검사 이력이 존재하지 않습니다.
                            </MyMDTypography>
                            </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                 

                    <MyMDBox
                        p={1}
                        onClick={() => {
                            setStatusModalTarget(null);
                        }}
                        sx={{
                            position: "absolute",
                            cursor: "pointer",
                            top: 30,
                            right: 30,
                        }}
                        >
                    <MyIcon name="AiOutlineClose" />
                    </MyMDBox>                        
                </MyMDBox>
                </MyMDBox>
                </MyMDBox>    
            </>    
          </>
        </MyModal>
    )
}

export default CheckHistory;