import { sendApi } from ".";
import { T_codeDtl } from "./code";
import { T_commmResult } from "./elevator";

export type T_message = {
    category?:string;
    title?:string;
    content?:string;
    insUserId?:string;
    insDate?:string;
    type?:string;
    sendDate?:string;
    page?:number;
    pageSize?:number;
    sendYn?:string;
    codeDtl?:T_codeDtl;
    sdate?:string;
    edate?:string;
}

export type T_messageListResult = {
    isSuccess:boolean;
    msg:string;
    list:T_message[];
    totalPage: number;
    total : number
 
}

async function sendMqtt(data:any){
  const response = await sendApi({
    method: "post",
    url: "/alarm/mqtt",
    data: {
      ...data,
    },
  });

  console.log("result",response);
  const result : T_commmResult = {
    ...response.data
  }
  return result;
}

async function getMstList(data?: T_message) {
    const response = await sendApi({
      method: "post",
      url: "/message/mst/list",
      data: {
        ...data,
      },
    });
    console.log("result:",response)
    const list = response.data.data.list;
    const totalPage = response.data.data.pages;
    const total = response.data.data.total;
     delete response.data.data;
    const result: T_messageListResult = {
      ...response.data,
      totalPage,
      total,
      list,
    };

    console.log(result)
    return result;
  }


  async function insertMst(data?: T_message) {
    const response = await sendApi({
      method: "post",
      url: "/message/mst/insert",
      data: {
        ...data,
      },
    });
    const result: T_commmResult = {
      ...response.data,
    };
    return result;
  }

  async function deleteMst(data?: T_message) {
    const response = await sendApi({
      method: "post",
      url: "/message/mst/delete",
      data: {
        ...data,
      },
    });
    const result: T_commmResult = {
      ...response.data,
    };
    return result;
  }

  const message = {
    getMstList,
    insertMst,
    deleteMst,
    sendMqtt
  }

  export default message;