import axios from "axios";
import values from "../values";
import msg from "./msg";
import user from "./user";
import code from "./code";
import elevator from "./elevator";
import check from "./check";
import partChange from "./partChange";
import troubleshooting from "./troubleshooting";
import { AiFillBoxPlot } from "react-icons/ai";
import { useUser } from "../contexts/user";
import message from "./message";
import board from "./board";


export type T_selectGroup = {
  name: string;
  value: string;
};


export type T_file = {
  fileUrl: string;
};

export type T_image = {
  fileUrl: string;
};

export function initApis() {
  axios.defaults.baseURL = values.server.url;
  axios.defaults.withCredentials = true;
}

export type T_sendApiConfig = {
  method: "get" | "put" | "post" | "delete";
  url: string;
  data?: any;
  isForm?: boolean;
};

export type T_sendApiResponseData = {
  isSuccess: boolean;
  msg: string;
  data?: any;
};

export async function sendApi(config: T_sendApiConfig) {
  //const {user} = useUser();
//  if(user?.accessToken) axios.defaults.headers.common['TOKEN'] = user?.accessToken;
  const response = await axios(config);
  const data = response.data;
  data.isSuccess = data?.code === "1";
  data.msg = data?.message || "";
  delete data.code;
  delete data.message;
  const newData: T_sendApiResponseData = data;
  const newResponse = {
    ...response,
    data: newData,
  };
  return newResponse;
}

export async function getApi(config:T_sendApiConfig){
  const response = await axios(config);
//  console.log(response);
  // const config = {headers: {
  //   'X-Requested-With': 'XMLHttpRequest'
  // }}
//   console.log("start")
//   try{
//  const response = await axios({
//     method: "get",
//     url: `/openapi/service/ElevatorInformationService/getElevatorViewN`,
//     params: {
//       serviceKey: "q+reJw+wOscsNbCV/cTaata1wXyMxk/2iLYufllgw/TNGvm37bVk1Ek1n5birNrfAxOcTkjGHUiWZEQVV0G5A==",
//       elevatorNo: data,
//     },
//   });
//   console.log("ffff:",response);
//   return response;
// }catch(err){
//     console.log(err);
//   }
  
   return response;
}

const apis = {
  msg,
  user,
  code,
  elevator,
  check,
  partChange,
  troubleshooting,
  message,
  board,
};

export default apis;
