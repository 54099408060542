import MyMDInput from "../../components/MyMDInput";
import MyMDBox from "../../components/MyMDBox";
import MyMDTypography from "../../components/MyMDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { useState } from "react";
import { T_admin } from "../../contexts/user";
import MyMDButton from "../../components/MyMDButton";
import apis from "../../apis";
import { TextField, Typography } from "@mui/material";
import useNav from "../../uses/useNav";
import MyEdit from "../../examples/MyEdit";

type T_adminInput= T_admin & {
    checkId? : boolean | false,
    userPass? : string,
    userPass1? : string,
    passCheck? : boolean,
}



function  AdminCreate(){

    const nav = useNav();

    const checkId = async() =>{
        if(adminInput.userId==""){
            alert("아이디를 입력해 주십시요");
            return;
        }
        const result = await apis.user.getAdminInfo({userId:adminInput?.userId });
        console.log(result);
        if( result.user == null) {
            alert("사용할수 있는 아이디 입니다.")
            setAdminInput({...adminInput,checkId:true})
        }else{
            alert("중복된 아이디 입니다.다른 아이디를 사용 하십시요.")
           setAdminInput({...adminInput,checkId:false})
        }

    }


    const insertAdmin = async() =>{

        if(!adminInput.checkId) {
            alert("아이디 중복 체커를 하시기 바랍니다");
            return;
        }
        if(!adminInput.passCheck) {
            alert("비밀번호를 확인 하시기 바랍니다");
            return;
        }
           const result = await apis.user.insertAdmin(adminInput);
           if(result.isSuccess){
            alert("등록이 완료 되었습니다.");
            nav.push("/admin")
           }else{
            alert(result.msg);
            return;
           }
    }


    const [adminInput,setAdminInput] = useState<T_adminInput>({userId:'',passCheck:false}); // 초기화 필요
     // 정규표현식을 사용하여 영문자, 숫자, 특수문자(!@#$%^&*) 조합만 허용
     // const isValidPassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]*$/.test(value);

    return(
   <DashboardLayout>
        <DashboardNavbar />
        <MyEdit 
          header={{
            title: "관리자 등록",
          }}
        >
        <></>
       <MyMDBox pt={1} pl={1} pr={1} borderRadius="md" bgColor="#FFFFFF">
         <MyMDBox pr={1}  pt={1}  pb={2} >
             <MyMDTypography ml={1} variant="h6">
                관리자 등록
             </MyMDTypography>
        <table   className="myTable"
                    style={{
                      marginTop: 5,
                      tableLayout: "fixed",
                    }}
        ><thead>
            
        </thead>
        <tbody>
              <tr>
              <td style={{width:'10%',backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 관리자아이디 </MyMDTypography></td>
              <td style={{padding:5}}><MyMDInput value={adminInput?.userId}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                    const sanitizedValue = value.replace(/[^A-Za-z0-9]/g, '');
                                    const updatedValue: T_adminInput = { ...adminInput, userId: sanitizedValue,checkId:false };
                                     setAdminInput(updatedValue);
                                    }
                                }
                                autoFocus
                                style={{width:'60%'}}
                            />
                           <MyMDButton
                        onClick={checkId}
                        href="#"
                        component="a"
                        rel="noreferrer"
                        variant="gradient"
                        color={adminInput?.checkId?"dark":"light"}
                        style={{marginLeft:5}}
                      >
                        중복확인
                      </MyMDButton>
                            </td>
              <td style={{width:'15%',backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption">관리자명</MyMDTypography></td>
              <td style={{padding:5}}><MyMDInput value={adminInput?.userNm}
                                onChange={(e:any)=>{
                                    console.log(e.target.value);
                                    const value = e.target.value;
                                    setAdminInput({...adminInput,userNm:value})
                              //   setAdminInput({...adminInput,userNm:e.tartget.value})
                                    // setAdminInput(e.target.value);
                                
                                }}
                                autoFocus
                                style={{width:'80%'}}
                            /></td>
              </tr>
              <tr>
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption">비밀번호</MyMDTypography></td>
              <td style={{padding:5}}>
                          <MyMDInput value={adminInput?.userPass}
                                onChange={(e:any)=>{
                                    const value = e.target.value;

                                    if(adminInput.userPass1===value) { 
                                        setAdminInput({...adminInput,userPass:value,passCheck:true})
                                    }
                                    else  {
                                        setAdminInput({...adminInput,userPass:value,passCheck:false})
                                     }

                                   
                                }}
                                autoFocus
                                type="password"
                                style={{padding:5}}
                            />
                             <Typography variant="caption" color='#ff0000' style={{display:adminInput.passCheck?'none':''}} >비밀번호가 일치 하지 않습니다.</Typography>
                            <br/>
                        <MyMDInput value={adminInput?.userPass1}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                    let check = true;
                                    console.log(value+":"+adminInput.userPass)
                                    if(adminInput.userPass===value) { 
                                        setAdminInput({...adminInput,userPass1:value,passCheck:true})
                                    }
                                    else  {
                                        setAdminInput({...adminInput,userPass1:value,passCheck:false})
                                     }
                                   
                                }}
                                type="password"
                                autoFocus
                                style={{padding:5}}
                            />   <Typography variant="caption"  > 비밀번호 확인</Typography>
                                 {/* <Typography variant="h6" component="h6"> 비밀번호는 영문자 숫자 특수문자(!@#$%^&*) 조합입니다.</Typography>                       */}
                            </td>

              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 파트명 </MyMDTypography></td>
              <td style={{padding:5}}><MyMDInput value={adminInput?.userPart}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                     setAdminInput({...adminInput,userPart:value})
                                }}
                                autoFocus
                                style={{width:'80%'}}
                            /></td>
              </tr>
              <tr >
              <td style={{backgroundColor:"#eeeeee"}}>  <MyMDTypography ml={1} variant="caption"> 비고 </MyMDTypography></td>
              <td colSpan={3} style={{height:'100%',padding:10}}>
                <TextField
                 multiline
                 rows={4}
                 value={adminInput?.userEtc}
                                onChange={(e:any)=>{
                                    const value = e.target.value;
                                     setAdminInput({...adminInput,userEtc:value})
                                }}
                                autoFocus
                                style={{width:'100%'}}
                />
            </td>
              </tr>
        </tbody>
        </table>
        <MyMDBox m={1} justifyContent="center" display="flex">
                            <MyMDBox pr={1}>
                            <MyMDButton m={1}    variant="gradient"
                                color="secondary"
                                onClick={()=>{
                                  nav.goBack();
                                }}
                                >취소</MyMDButton>              
                                </MyMDBox>
                          <MyMDBox pr={1}>
                          <MyMDButton m={1} variant="gradient"
                            onClick={()=>{
                                insertAdmin();
                            }}
                            color="info">등록</MyMDButton>
                          </MyMDBox>
                          <MyMDBox pr={1}>
                          <MyMDButton m={1}   
                            color="warning">삭제</MyMDButton>
                          </MyMDBox>

        </MyMDBox>
        </MyMDBox>
        </MyMDBox>
        </MyEdit>

      </DashboardLayout>    
    )

}

export default AdminCreate;