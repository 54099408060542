import useNav from "../../uses/useNav";
import MyMDButton from "../../components/MyMDButton";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MyTable from "../../examples/MyTable";
import { useCallback, useEffect, useState } from "react";
import MyMDBox from "../../components/MyMDBox";
import MyMDTypography from "../../components/MyMDTypography";
import MyIcon from "../../components/MyIcon";
import MDTypography from "../../components/MDTypography";
import apis from "../../apis";
import message, { T_message, T_messageListResult } from "../../apis/message";
import MyMDInput from "../../components/MyMDInput";
import formats from "../../modules/formats";
import Footer from "../../examples/Footer";
import { Navigate } from "react-router-dom";
import { useUser } from "../../contexts/user";
function MessageList()
{
    const nav = useNav();
    const { user } = useUser();
    const isNavReplace = !nav.search;
    const [messageList,setMessageList] = useState<T_message[] | null >(null);

    const page = nav.params.page;
    console.log(page)
    const pagesize = 10;
    const [total, setTotal] = useState<null | number>(null);
    const [totalPage,setTotalPage] = useState<number>();

    const today = new Date();
    const preMonth = today.setMonth(today.getMonth()-1);
    const [sdate, setSdate] = useState(nav.params.date || formats.date(new Date(preMonth), "yyyy-mm-dd"));
    const [edate, setEdate] = useState(nav.params.date || formats.date(new Date(), "yyyy-mm-dd"));
  

    const getMessageList = useCallback(async()=>{
        const result = await apis.message.getMstList({page:Number(page),pageSize:pagesize,sdate:sdate,edate:edate})
        if(result.isSuccess){
          //console.log(result.list)
                    setTotal(result.total);
                      setTotalPage(result.totalPage);
                      setMessageList(result.list);
        }

    },[page,sdate,edate])

    useEffect(()=>{
        getMessageList();
 //       setMessageList([]);
    },[nav.params.page,sdate,edate])

    if (!user) {
      return <Navigate to="/signin" />;
    } else if (isNavReplace) {
      return <Navigate replace to={`/message?page=1`} />;
    } else {
    return(
        <DashboardLayout>
        <DashboardNavbar />
        {messageList && (
          <>
        <MyTable
             pagesize={pagesize}
             totalPage={totalPage}
              header={{
                title: "메세지 관리",
                rightComponent: (
                  <MyMDBox
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <MyMDButton
                      onClick={(evt: any) => {
                        evt.preventDefault();
                        nav.push(`/message/create`);
                      }}
                      href="#"
                      component="a"
                      rel="noreferrer"
                      variant="gradient"
                      color="light"
                    >
                      등록
                    </MyMDButton>
                  </MyMDBox>
                ),
              }}
              filter={
                <>
                  <MyMDInput
                    type="date"
                    value={sdate}
                    onChange={(evt: any) => {
                      setSdate(evt.target.value);
                    }}
                  />&nbsp;-&nbsp;<MyMDInput
                  type="date"
                  value={edate}
                  onChange={(evt: any) => {
                    setEdate(evt.target.value);
                  }}
                />
                </>   
              }

              table={(() => {
                type column = "no" | "카테고리" | "제목" | "내용" | "일자" | "발송여부";
                type low = {
                  [name in column]: JSX.Element;
                };
                const columnList: column[] = [
                  "no",
                  "카테고리",
                  "제목",
                  "내용",
                  "일자",
                  "발송여부",
                ];

                return {
                  columns: columnList.map((column) => {
                    let width = "";
                    switch (column) {
                      case "no": {
                        width = "5%";
                        break;
                      }
                      case "카테고리": {
                        width = "10%";
                        break;
                      }
                      case "제목": {
                        width = "20%";
                        break;
                      }
                      case "내용": {
                        width = "20%";
                        break;
                      }
                      case "일자": {
                        width = "15%";
                        break;
                      }
                      case "발송여부": {
                        width = "15%";
                        break;
                      }
                    }

                    return {
                      Header: column,
                      accessor: column,
                      width,
                    };
                  }),
                  rows: messageList.map((message:T_message, idx:number) => {
                    const row: low = {
                      no: (
                        <MyMDTypography
                          display="block"
                          variant="caption"
                          color="text"
                          fontWeight="regular"
                        >
                          {Number(total)-(pagesize*(Number(page)-1))-idx}
                        </MyMDTypography>
                      ),
                      ["카테고리"]: (
                        <MyMDTypography
                          // onClick={(evt: any) => {
                          //   evt.preventDefault();
                          //   nav.push(`/group/0`);
                          // }}
                          // href="#"
                          display="block"
                          component="a"
                          variant="caption"
                          fontWeight="medium"
                        >
                          {message.codeDtl?.codeDtlNm} 
                        </MyMDTypography>
                      ),
                      ["제목"]: (
                        <MyMDBox
                          onClick={(evt: any) => {
                            evt.preventDefault();
                      //      nav.push(`/elevator/${elevator.elevatorNo}`);
//                            nav.pushParam('/elevator/info',{elevatorNo:elevator.elevatorNo});
                          }}
                          href="#"
                          component="a"
                          alignItems="center"
                        >
                          <MyMDTypography
                            display="block"
                            variant="caption"
                            fontWeight="medium"
                            mb={1}
                          >
                            {message.title}
                            {/* 602동 1호기 */}
                          </MyMDTypography>
                          <MyMDTypography
                            display="block"
                            variant="caption"
                            fontWeight="regular"
                            color="text"
                          >
                          </MyMDTypography>
                        </MyMDBox>
                      ),
                      ["내용"]: (
                        <MyMDTypography
                          display="block"
                          variant="caption"
                          fontWeight="regular"
                          color="text"
                        >
                          {message.content}
                        </MyMDTypography>
                      ),
                      ["일자"]: (
                        <MyMDTypography
                          display="block"
                          variant="caption"
                          fontWeight="regular"
                          color="text"
                        >
                          {message.insDate}
                        </MyMDTypography>
                      ),
                      ["발송여부"]:  <MyMDTypography
                      display="block"
                      variant="caption"
                      fontWeight="regular"
                      color="text"
                    >
                    </MyMDTypography>,
                    };
                    return row;
                  })                   
                  ,
                };
              })()}
            />
         <Footer />
            </>
            )}
        </DashboardLayout>

    )
            }
}

export default MessageList;