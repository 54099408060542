/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

==========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Grid from "@mui/material/Grid";
import MyMDBox from "../../components/MyMDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import CheckingElevatorList from "../../layouts/dashboard/index/CheckingElevatorList";
import { useUser } from "../../contexts/user";
import { Navigate } from "react-router-dom";
import CheckingCountCard from "./index/CheckingCountCard";
import CheckCompleteCountCard from "./index/CheckCompleteCountCard";
import CheckFailCountCard from "./index/CheckFailCountCard";
import CheckCompleteDayChart from "./index/CheckCompleteDayChart";
import CheckCompleteMonthChart from "./index/CheckCompleteMonthChart";
import CheckFailDayChart from "./index/CheckFailDayChart";
import ElevStatus from "./index/ElevStatus";
import apis from "../../apis";
import { useCallback, useEffect, useState } from "react";
import { T_elevator } from "../../apis/elevator";
import { T_codeDtl } from "../../apis/code";
import MyMDTypography from "../../components/MyMDTypography";
import { group } from "console";
import { GroupLayout } from "./groupLayout/GroupCard";

export type  T_GroupElev  = {
    code?:string;
    codeDtlNm?:string;
    list? : T_elevator[];

}
function DashboardIndexLayout() {
  const { user } = useUser();
  const [groupList,setGroupList] = useState<T_GroupElev[]>();


  const getElevatorList = async (group:T_codeDtl) => {
    try {
      if(group.code !== undefined){
        const result = await apis.elevator.getList(
          {
            page:0,
            pagesize:100,
            groupCode:group.code,
          }
        );
        if (result.isSuccess) {
          let result1 : T_GroupElev = {};
          result1 = {code:group.code,codeDtlNm:group.codeDtlNm,list:result.elevatorList};
          return result1;
        } 
  
      }

    } catch (err: any) {
      console.log(err.message);
    }
  };

  const getGroupList = async () => {
    try {
      console.log("start");
      const result = await apis.code.getCodeDtlList({ pCode: "GR" });
      console.log(result.list);
  
      if (result.isSuccess && result.list) {
        const item_temp: T_GroupElev[] = [];
        for (const item of result.list) {
          const newGroup = await getElevatorList(item);
          console.log("newGroup:", newGroup);
          if (newGroup) {
            item_temp.push(newGroup);
          }
        }
  
        setGroupList(item_temp);
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };
   
  // const getGroupList = async () => {
  //   try {
  //     console.log("start")
  //     const result = await apis.code.getCodeDtlList({pCode:"GR"})
  //     console.log(result.list);
  //     if(result.isSuccess && result.list){
  //         let item_temp :T_GroupElev[] = [];
  //         await Promise.all(
  //         result.list.map(async(item,idx)=>{
  //            const  newGroup =  await getElevatorList(item);
  //            console.log("newGroup:",newGroup)
  //            if(newGroup) item_temp.push(newGroup);
            
  //         })
  //         );
  //            setGroupList(item_temp);
  //      } 
  //   } catch (err: any) {
  //     console.log(err.message);
  //   }
  // }

  useEffect(()=>{
    const init = async() =>{
        console.log("getGroupList")
       await getGroupList();
    }
    init();
  },[]);





  if (!user) {
    return <Navigate replace to={`/signin`} />;
  } else {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MyMDBox py={3}  >
    
          <Grid container spacing={3} >
             {groupList && groupList.map((item,idx)=>{
                    return(
                      <Grid item xs={12} sm={12} md={12} lg={12} key={idx} mt={2} >
                      <GroupLayout color="#345388" items={item} />
                      </Grid>
                    )
                })}
            {/* <CheckingCountCard />
            <CheckCompleteCountCard />
            <CheckFailCountCard /> */}
          </Grid>
          {/* <MyMDBox mt={4.5}>
            <Grid container spacing={3}>
              <CheckCompleteDayChart />
              <CheckCompleteMonthChart />
              <CheckFailDayChart />
            </Grid>
          </MyMDBox> */}
        </MyMDBox>
        <Footer />
      </DashboardLayout>
    );
  }
}

export default DashboardIndexLayout;
