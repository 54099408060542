import MyMDBox from "../../components/MyMDBox";
import MyMDTypography from "../../components/MyMDTypography";
import { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { T_elevator, T_elevatorGetResult } from "../../apis/elevator";
import MyMDButton from "../../components/MyMDButton";
import MyMDInput from "../../components/MyMDInput";
import apis from "../../apis";
import MDSnackbar from "../../components/MDSnackbar";
import { Box, CircularProgress } from '@mui/material';
import { useProgress } from "../../contexts/ProgressContextProvider";

function ElevatorCreateLayout(){
   const inputRef = useRef(null);
    const [isAlret,setIsAlert] = useState(false);
    const [elevator,setElevator] = useState<T_elevator|null>();
    const [elevatorNo,setElevatorNo] = useState('');
    const [successSB,setSuccessSB] = useState(false);

    const { setShowProgress } = useProgress();


     useEffect(()=>{
        
    },[])

    const elevatorGet = async (data:string) =>{
        if(data==null || data.trim()==''){
            alert("승강기 번호를 입력하여 주십시요");
            return;
        }
        setShowProgress(true);
        const result  = await apis.elevator.getApiInfo(data);
        console.log(result);
        if(result.isSuccess){
            console.log("success");
            setElevator(result.elevator);
            setShowProgress(false);     
        }else{
          setElevator(null);
          setElevatorNo('');
          alert("존재하지 않는 승강기번호입니다.\n확인후 다시 실행하시기 바랍니다.");
          setShowProgress(false);
          return;
          // openSuccessSB();
          // console.log("fail");
        }
        setShowProgress(false);
    }

    const saveElev= async()=>{
        
        if(elevator){
        
           if(window.confirm("정말로 등록 하시겠습니까?")){
            const result = await apis.elevator.saveElevator(elevator);
            if(result.isSuccess){
              alert("등록이 완료 되었습니다.")
              setElevator(null);
            }else{
              alert("등록중 오류가 발생했습니다("+result.msg+")")
            }
          }else{
            return;
          }

        }else{
      //    if (inputRef.current !== null) 
             alert('등록할 엘리베이터 정보를 가져오시기 바랍니다.')
        }
    }

    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB  = () => setSuccessSB(false);

    const renderSuccessSB = (
      <MDSnackbar
        color="success"
        icon="check"
        title="Alert"
        content="존재하지 않는 엘리베이터 번호입니다. 확인후 다시 실행하십시요"
        dateTime=""
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
    );

        return(
          <>
            <DashboardLayout>
            <DashboardNavbar />
            <>
            {renderSuccessSB}

            <MyMDBox pt={1} pl={1} pr={1} borderRadius="md" bgColor="#FFFFFF">
                <MyMDBox
                  pr={1}
                  pt={1}
                  pb={2}
                >
             <MyMDTypography ml={1} variant="h6">
                승강기 정보
             </MyMDTypography>
             <table
                    className="myTable"
                    style={{
                      marginTop: 5,
                      tableLayout: "fixed",
                    }}
                  >
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            승강기번호
                          </MyMDTypography>
                        </td>
                        <td >
                          <MyMDTypography variant="caption" fontWeight="medium">
                            <MyMDInput value={elevatorNo}
                                onChange={(e:any)=>{setElevatorNo(e.target.value);}}
                                autoFocus
                            ></MyMDInput>
                            
                          </MyMDTypography>
                        </td>
                        <td colSpan={2}>
                        <MyMDBox m={1} justifyContent="left" display="flex">
                          <MyMDBox pr={1}>
                          <MyMDButton m={1}   variant="gradient"

                            onClick={()=>{
                                 console.log("elevatorNo:",elevatorNo);
                                 elevatorGet(elevatorNo) }}
                            color="secondary">가져오기</MyMDButton>
                          </MyMDBox>
                          </MyMDBox>
                        </td>
                      </tr>
                      </tbody>
                      </table>
                      <table
                    className="myTable"
                    style={{
                      marginTop: 5,
                      tableLayout: "fixed",
                    }}
                  >
                    <thead></thead>
                    <tbody>
                    <tr>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            건물명
                          </MyMDTypography>
                        </td>
                        <td colSpan={3}>
                          <MyMDTypography variant="caption" fontWeight="medium">
                            {elevator?.buldNm} {elevator?.installationPlace}
                          </MyMDTypography>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            소재지
                          </MyMDTypography>
                        </td>
                        <td colSpan={3}>
                          <MyMDTypography variant="caption" fontWeight="medium">
                            {elevator?.address1}  {elevator?.address2}
                          </MyMDTypography>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            제조업체
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                            {elevator?.manufacturerName}
                          </MyMDTypography>
                        </td>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            모델명
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                          {elevator?.elvtrModel}
                          </MyMDTypography>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            종류
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                            {elevator?.elvtrKindNm}
                          </MyMDTypography>
                        </td>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            상태
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                           {elevator?.elvtrStts}
                          </MyMDTypography>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            적재하중
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                            {elevator?.liveLoad}
                          </MyMDTypography>
                        </td>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            최대정원
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                          {elevator?.ratedCap}
                          </MyMDTypography>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            운행구간
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                            {elevator?.shuttleSection}
                          </MyMDTypography>
                        </td>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            운행층수
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                           {elevator?.shuttleFloorCnt}
                          </MyMDTypography>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            지상층수
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                            {elevator?.divGroundFloorCnt}
                          </MyMDTypography>
                        </td>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            지하층수
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                            {elevator?.divUndgrndFloorCnt}
                          </MyMDTypography>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            정격속도
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                            {elevator?.ratedSpeed}
                          </MyMDTypography>
                        </td>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            최종검사일
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                          {elevator?.lastInspctDe}
                          </MyMDTypography>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            검사유효기간
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                            {elevator?.applcBeDt} ~ {elevator?.applcEnDt}
                          </MyMDTypography>
                        </td>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            설치일/최초설치일
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                          {elevator?.installationDe} / {elevator?.frstInstallationDe}
                          </MyMDTypography>
                        </td>
                      </tr>
                      </tbody>
                      </table>      
                      <table
                    className="myTable"
                    style={{
                      marginTop: 5,
                      tableLayout: "fixed",
                    }}
                  >
                    <thead></thead>
                    <tbody>

     
                      <tr>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                             유지관리업체
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                            {elevator?.manufacturerName}
                          </MyMDTypography>
                        </td>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            유지관리업체 연락처
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                          {elevator?.manufacturerTelno}
                          </MyMDTypography>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            하도급/공동수급업체명
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                           - 
                          </MyMDTypography>
                        </td>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            하도급 / 공동수급 연락처
                          </MyMDTypography>
                        </td>
                        <td>
                          <MyMDTypography variant="caption" fontWeight="medium">
                           -
                          </MyMDTypography>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <MyMDTypography color="text" variant="caption" fontWeight="regular">
                            최종 검사 기관
                          </MyMDTypography>
                        </td>
                        <td colSpan={3}>
                          <MyMDTypography variant="caption" fontWeight="medium">
                            {elevator?.inspctInstt}
                          </MyMDTypography>
                        </td>
                        </tr>
                      </tbody>
                      </table>      

              </MyMDBox>
              <MyMDBox m={1} pb={4} justifyContent="center" display="flex">
                          <MyMDBox pr={1}>
                          {elevator &&  
                          <MyMDButton m={1}   variant="gradient"
                            color={elevator?"info":"primary"}
                             
                            onClick={()=>{
                                saveElev();
                            }}
                            >등록하기</MyMDButton>
                          }
                          </MyMDBox>
                          {/* <MyMDBox pr={1}>
                          <MyMDButton m={1}   variant="gradient"
                            color="primary">삭제</MyMDButton>
                          </MyMDBox> */}
                          </MyMDBox>

            </MyMDBox>       
          </>

            </DashboardLayout>
            </>

        )

}

export default ElevatorCreateLayout;
