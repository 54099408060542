function getBitAtPosition(byteArray:any, bitPosition:number) {
    const byteIndex = Math.floor(bitPosition / 8); // 비트 위치로부터 해당하는 바이트의 인덱스를 계산
    const bitIndex = bitPosition % 8; // 해당 바이트 내에서 비트 위치 계산
  
    if (byteIndex >= byteArray.length) {
      throw new Error('비트 위치가 바이트 배열의 범위를 초과했습니다.');
    }
  
    // 비트 값을 추출하기 위해 비트 마스크 사용
    
//    const bitValue = (byteArray[byteIndex] >> (7 - bitIndex)) & 1; // 7 - bitIndex로 원하는 비트 위치로 이동한 후, AND 1로 추출

    const retValue = (byteArray & (1 << bitIndex)) !== 0;
    return retValue;
  }
  
const util = {
    getBitAtPosition
  };
  

  export default util;